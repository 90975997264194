import React, { ReactNode } from "react";
import cn from "classnames/bind";
import _ from "lodash";
import { RiErrorWarningFill } from "react-icons/ri";
import styles from "./Button.module.scss";

const cx = cn.bind(styles);

const Button: React.FC<{
    type?: "button" | "submit";
    variant?: string;
    icon?: any;
    text: string;
    border?: boolean;
    fitContent?: boolean;
    disabled?: boolean;
    onClick?: Function;
    className?: string;
    displayWarning?: boolean;
    colorWarning?: string;
    textColor?: string;
}> = ({
    type = "button",
    variant = "gradient",
    border = false,
    fitContent = false,
    disabled = false,
    className,
    icon,
    text,
    onClick,
    displayWarning = false,
    colorWarning = "#FFC700",
    textColor = "#FFFFF",
}) => {
    return (
        <button
            type={type}
            className={
                cx(
                    "button",
                    !_.isNil(variant) && "button-" + variant,
                    border && "button-border",
                    disabled && "button-disabled",
                    fitContent && "button-fit-content"
                ) + (_.isNil(className) ? "" : ` ${className}`)
            }
            disabled={disabled}
            onClick={() => {
                !_.isNil(onClick) && onClick();
            }}
        >
            {!_.isNil(icon) && <span className={cx("button-icon")}>{icon}</span>}
            <span className={cx("button-text")} style={{ color: textColor }}>
                {text}
            </span>
            {displayWarning && <RiErrorWarningFill className={cx("button-warning-icon")} style={{ color: colorWarning }} />}
        </button>
    );
};

export default Button;
