/* eslint-disable react/jsx-no-target-blank */
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import classNames from "classnames/bind";
import styles from "./Footer.module.scss";
import PowerByImage from "src/assets/images/orai_frame_dark.svg";
import GithubIcon from "src/assets/icons/GithubIcon";
import MediumIcon from "src/assets/icons/MediumIcon";
import RedditIcon from "src/assets/icons/RedditIcon";
import TelegramIcon from "src/assets/icons/TelegramIcon";
import TwitterIcon from "src/assets/icons/TwitterIcon";
import YoutubeIcon from "src/assets/icons/YoutubeIcon";
import PlainLinks from "../PlainLinks/PlainLinks";
import { DOMAIN } from "src/consts/domain";
import AiriIcon from "src/assets/icons/AiriIcon";

const cx = classNames.bind(styles);

const AboutLinkData = [
    {
        title: "Introduction",
        path: "https://docs.airight.io/whitepaper/introduction",
    },
    {
        title: "AIRI Tokenomics",
        path: "https://docs.airight.io/whitepaper/tokenomics",
    },
    // {
    //     title: "Gallery Introduction",
    //     path: "https://blog.orai.io/recap-of-airight-introduction-video-part-ii-utilizing-oraichain-ecosystems-and-nft-x-defi-e31720338dff",
    // },
    {
        title: "Careers",
        path: "https://drive.google.com/drive/folders/1FfLEMpUG7yi2z0x0xKqTWXEw5bx73Vhy?usp=sharing",
    },
    // {
    //     title: "FAQs",
    //     path: "https://gov.orai.io/t/faqs-farming-samorai-token-the-warrior-gallery/88",
    // },
    {
        title: "Media Kit",
        path: "/media-kit",
    },
    {
        path: "/buy-airi",
        title: "Buy AIRI",
        description: "",
        Icon: AiriIcon,
    },
    {
        path: "https://patentscope.wipo.int/search/en/detail.jsf?docId=WO2022200834&_cid=P12-LBD67M-45114-1",
        title: "Patent",
    },
];

const OraichainEcosystem = [
    {
        title: "aiRight",
        path: "https://airight.io/",
    },
    {
        title: "Oraichain Wallet",
        path: "https://app.oraiwallet.io/",
    },
    {
        title: "Oraichain Studio",
        path: "https://developer.orai.io/",
    },
    // {
    //     title: "Oraichain DataHub",
    //     path: "https://blog.orai.io/airi-airdrop-program-e1726bc3d74f",
    // },
    {
        title: "Oraiscan",
        path: "https://scan.orai.io/",
    },
    {
        title: "yAI.Finance",
        path: "https://yai.finance/",
    },
];

const ServiceLinkData = [
    {
        title: "AI-based NFT Generation",
        path: "/ai-models",
    },
    {
        title: "AI-based NFT Authenticity Verification",
        path: "/nft-content-authenticity",
        classMore: "break-white",
    },
    {
        title: "Royalty Protocol",
        path: "https://blog.airight.io/adapting-oraichain-royalty-protocol-for-nfts-on-airight-8c1e74a36be3",
    },
    {
        title: "NFT Marketplace",
        path: "/marketplace",
    },
    {
        title: "NFT Gallery",
        path: "/nft-gallery",
    },
    {
        title: "NFT Farming",
        path: "token-farming",
    },
    // {
    //     title: "AI Oracle services",
    //     path: "https://airight.io/original-check",
    // },
    {
        title: "Support",
        path: "https://t.me/airight_io",
    },
];

const CommunityLinkData = [
    {
        title: "Medium",
        path: "https://blog.airight.io/",
        Icon: MediumIcon,
    },
    {
        title: "Telegram",
        path: "https://t.me/airight_io",
        Icon: TelegramIcon,
    },
    {
        title: "Twitter",
        path: "https://twitter.com/aiRight_io",
        Icon: TwitterIcon,
    },
    {
        title: "Youtube",
        path: "https://youtube.com/channel/UCyckcs_Fm8kU4o2Y1_KPjXg",
        Icon: YoutubeIcon,
    },
    {
        title: "Reddit",
        path: "https://www.reddit.com/r/Oraichain_Official",
        Icon: RedditIcon,
    },
    {
        title: "Github",
        path: "https://github.com/oraichain",
        Icon: GithubIcon,
    },
];

const Footer = ({}) => {
    return (
        <div className={cx("footer")}>
            <div className={cx("top-section", "container")}>
                <Container>
                    <Grid container={true}>
                        <Grid container={true} item={true} lg={5} xs={12}>
                            <Grid item={true} lg={12} xs={12}>
                                <div className={cx("email")}>
                                    <img src={PowerByImage} alt='' width={135} height={61} />
                                    <div className={cx("email-function", "mt-3")}>General Inquiries</div>
                                    <div className={cx("email-address")}>Email to: contact@airight.io</div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container={true} item={true} lg={7} xs={12} className='mt-4'>
                            <Grid item={true} lg={3} md={3} xs={6}>
                                <PlainLinks title='About' items={AboutLinkData} />
                            </Grid>
                            <Grid item={true} lg={3} md={3} xs={6}>
                                <PlainLinks title='Services' items={ServiceLinkData} />
                            </Grid>
                            <Grid item={true} lg={3} md={3} xs={6}>
                                <PlainLinks title='Oraichain Ecosystem' items={OraichainEcosystem} />
                            </Grid>
                            <Grid item={true} lg={3} md={3} xs={6}>
                                <PlainLinks className={cx("community")} title='Community' items={CommunityLinkData} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={cx("bottom-section")}>
                <Container className='container' fixed={true}>
                    <div className={cx("bottom-section-body")}>
                        <div className={cx("copy-right")}>©2020 - 2023 Oraichain Labs. All rights reserved.</div>
                        <ul className={cx("nav")}>
                            <li className={cx("nav-item")}>
                                <a className={cx("nav-link")} href='https://docs.orai.io/others/privacy-policy' target='_blank'>
                                    Privacy Policy
                                </a>
                            </li>

                            {/* <li className={cx("nav-item")}>
                                <a
                                    className={cx("nav-link")}
                                    href="/"
                                    target="_blank"
                                >
                                    Customer Support
                                </a>
                            </li>

                            <li className={cx("nav-item")}>
                                <a
                                    className={cx("nav-link")}
                                    href="/"
                                    target="_blank"
                                >
                                    Careers
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Footer;
