// @ts-nocheck
import { useWeb3React } from "@web3-react/core";
import cn from "classnames/bind";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { selectWallet } from "src/store/slices/walletSlice";
import { IWallet } from "src/types/redux";

// components
import ConnectWalletButton from "src/components/common/Header/ConnectWalletButton";
import MobileDropdownProfile from "src/components/common/Header/MobileDropdownProfile";
import { Links } from "src/components/common/Header/NavLinks";
import MarketApproveDialog from "src/components/common/MarketApproveDialog";
import VerifyWalletDialog from "../VerifyWalletDialog/VerifyWalletDialog";
import NavLinks from "./NavLinks";
import Notification from "./Notification";

// icons
import { CaretLeftOutlined } from "@ant-design/icons";
import IconDarkLightTheme from "src/assets/icons/icon-dark-light-theme.svg";
import IconGithub from "src/assets/icons/icon-github.svg";
import IconMedium from "src/assets/icons/icon-medium.svg";
import IconMyProfile from "src/assets/icons/icon-my-profile.svg";
import IconNavigationDark from "src/assets/icons/icon-navigation-dark.svg";
import IconNavigation from "src/assets/icons/icon-navigation.svg";
import IconReddit from "src/assets/icons/icon-reddit.svg";
import IconTelegram from "src/assets/icons/icon-telegram.svg";
import IconTwitter from "src/assets/icons/icon-twitter.svg";
import IconYoutube from "src/assets/icons/icon-youtube.svg";
import logo from "src/assets/images/aiRightIcon.png";

// store & hooks
import { selectThemeId, setActiveThemeId } from "src/store/slices/activeThemeSlice";
import { useCheckMobileScreen, useEagerConnect, useInactiveListener } from "src/utils/hooks";

// theme
import { themeIds } from "src/consts/theme";
import ThemeSetup from "src/utils/themeSetup";

// styles
import { isNil } from "lodash";
import styles from "./Header.module.scss";
import "./Header.scss";

const cx = cn.bind(styles);

const ercPathname = [
  "/token-farming",
  "/nft-gallery",
  "/swap",
  "/view-lock",
  "/lock-liquidity",
  "/locker",
  "/nft-detail/0x"
];

const showFarming = !localStorage.getItem("showedCallArtist");

const Header: React.FC<{}> = () => {
  const context = useWeb3React();
  const { connector, account, deactivate, activate } = context;
  const [activatingConnector, setActivatingConnector] = useState();
  const [showStake, setShowStake] = useState(showFarming);
  const [openVerifyDialog, setOpenVerifyDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  // const [claimModal, setClaimModal] = useState(false);
  // const [openSwapDialog, setOpenSwapDialog] = useState(false);
  const isMobileScreenLTE = useCheckMobileScreen(1200);
  const history = useHistory();
  const location = useLocation();
  const wallet = useSelector<IWallet>(selectWallet);
  const mobileWalletRef = useRef();
  const mobileNavbarRef = useRef();
  const [mobileWalletInforDropdown, setMobileWalletInforDropdown] = useState(false);
  const [mobileNavigationDropdown, setMobileNavigationDropdown] = useState(false);
  const { publicAddress } = wallet;
  const isErc20 = ercPathname.some((i) => location.pathname.includes(i));
  const walletAddress = isErc20 ? account || wallet?.address : publicAddress;
  const erc20Address = account || wallet?.address;
  const activeThemeId = useSelector(selectThemeId);
  const dispatch = useDispatch();
  const { isDarkTheme } = ThemeSetup();
  const isConnected = walletAddress && walletAddress !== "0x";
  const [dataPriceBNB, setDataPriceBNB] = useState({});

  const getPriceBNB = async () => {
    try {
      const { binancecoin: data } = await fetch(
        "https://price.market.orai.io/simple/price?ids=binancecoin&vs_currencies=usd"
      ).then((res) => res.json());

      setDataPriceBNB(data);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    getPriceBNB();
  }, []);

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  React.useEffect(() => {
    isMobileScreenLTE && document?.addEventListener("mousedown", handleClickOutside);
    return () => {
      document?.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileScreenLTE, mobileWalletRef, mobileNavbarRef]);

  const handleClickOutside = (event) => {
    if (
      isMobileScreenLTE &&
      mobileWalletRef &&
      !mobileWalletRef?.current?.contains?.(event?.target)
    ) {
      setMobileWalletInforDropdown(false);
    }

    if (
      isMobileScreenLTE &&
      mobileNavbarRef &&
      !mobileNavbarRef?.current?.contains?.(event?.target)
    ) {
      setMobileNavigationDropdown(false);
    }
  };

  const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!activatingConnector);

  const navbar = useRef();

  const hideDropdown = useCallback(() => {
    return setMobileNavigationDropdown(false);
  }, [setMobileNavigationDropdown]);

  const handleClickStake = (e) => {
    e.preventDefault();
    setShowStake(false);
    localStorage.setItem("showedCallArtist", true);
    window.open(
      "https://blog.airight.io/nft-tokenization-launchpad-open-call-for-artists-c3548e269ce7",
      "_blank"
    );
  };

  const handleCloseStake = () => {
    setShowStake(false);
    localStorage.setItem("showedCallArtist", true);
  };

  const [navLinkChildrenKey, saveNavLinkChildrenKey] = useState<String>(null);

  const toggleMode = () => {
    const themeId = activeThemeId === themeIds.LIGHT ? themeIds.DARK : themeIds.LIGHT;
    dispatch(setActiveThemeId(themeId));
  };

  const toggleNavigationDropdown = useCallback(() => {
    setMobileNavigationDropdown(true);
  }, [setMobileNavigationDropdown]);

  const handleBackStepMenu = useCallback(() => {
    return saveNavLinkChildrenKey(null);
  }, [saveNavLinkChildrenKey]);

  const handleNextStepMenu = useCallback(
    (path) => {
      return saveNavLinkChildrenKey(path);
    },
    [saveNavLinkChildrenKey]
  );

  const handleOnClickMyProfile = useCallback(() => {
    setMobileNavigationDropdown(false);

    if (!isConnected) {
      return setMobileWalletInforDropdown(true);
    }

    return history.push(`/profile/${wallet?.publicAddress}`);
  }, [setMobileWalletInforDropdown, history, wallet]);

  const navLinkChildren = Links.find((item) => item.path == navLinkChildrenKey);

  const renderMenuNavLinkChildren = useMemo(() => {
    if (navLinkChildren) {
      return (
        <div className={cx("nav-link-children-mobile")}>
          <div className={cx("nav-link-children-mobile-header")} onClick={handleBackStepMenu}>
            <CaretLeftOutlined />
            <div className={cx("title")}>{navLinkChildren?.title}</div>
          </div>
          <div className={cx("divider")} />
          {navLinkChildren?.items?.map(({ path, title, icon, isBlank }) => {
            return (
              <div
                key={title}
                className={cx("nav-item-children-mobile")}
                onClick={() => {
                  setMobileNavigationDropdown(false);
                  isBlank ? window.open(path) : history.push(path);
                }}
              >
                {icon && <img src={icon} alt={title} className={cx("icon")} />}
                <div>{title}</div>
              </div>
            );
          })}
        </div>
      );
    }
  }, [navLinkChildren, handleBackStepMenu, setMobileNavigationDropdown, history]);

  const renderNotifications = () =>
    !isNil(wallet?.publicAddress) && wallet?.publicAddress !== "0x" && <Notification />;

  return (
    <>
      {/* {showStake && (
                <div className={cx("airi-farming")}>
                    <div className={cx("airi-farming-text")}>
                        NFT Tokenization Launchpad. <br />
                        <a
                            href='https://blog.airight.io/nft-tokenization-launchpad-open-call-for-artists-c3548e269ce7'
                            onClick={handleClickStake}
                            className={cx("stake-now")}
                        >
                            Open Call for Artists
                        </a>
                        <img onClick={handleCloseStake} src={CancelIcon} className={cx("announcement-bar-close")} />
                    </div>
                </div>
            )} */}
      {isMobileScreenLTE ? (
        <>
          {/* <Navbar fixed='top' expand='xl' className={cx("navbar", showStake ? "navbar-staked" : "")}> */}
          <Navbar fixed="top" expand="xl" className={cx("navbar")}>
            <Container>
              <div className={cx("navbar-container")}>
                <Navbar.Brand className="navbar-brand" as={Link} to="/">
                  <img
                    height={50}
                    width={110}
                    className={cx("navbar-brand-image")}
                    src={logo}
                    alt="AI Right"
                  />
                </Navbar.Brand>

                <div className={cx("navbar-right")}>
                  {renderNotifications()}
                  <ConnectWalletButton
                    setMobileWalletInforDropdown={setMobileWalletInforDropdown}
                    walletAddress={walletAddress}
                    erc20Address={erc20Address}
                    oraiAddress={publicAddress}
                    isErc20={isErc20}
                    onConnectWallet={hideDropdown}
                  />
                  <div
                    ref={navbar}
                    className={cx("nav-toggle")}
                    aria-controls="basic-navbar-nav"
                    onClick={toggleNavigationDropdown}
                  >
                    <img src={isDarkTheme ? IconNavigationDark : IconNavigation} alt="" />
                  </div>
                </div>
              </div>
            </Container>
          </Navbar>

          <div
            ref={mobileNavbarRef}
            className={cx("mobile-menu-dropdown", {
              "mobile-menu-dropdown-active": mobileNavigationDropdown
            })}
          >
            <div className={cx("content-menu-dropdown")}>
              {navLinkChildrenKey ? (
                renderMenuNavLinkChildren
              ) : (
                <Nav className={`justify-content-end align-items-center ${cx("navbar-dropdown")}`}>
                  <NavLinks
                    onItemClick={hideDropdown}
                    current={history.location.pathname}
                    handleNextStepMenu={handleNextStepMenu}
                  />
                </Nav>
              )}

              <div className={cx("menu-setting")}>
                <div className={cx("nav-item-children-mobile")} onClick={handleOnClickMyProfile}>
                  <img src={IconMyProfile} alt="My profile" className={cx("icon")} />
                  <div>My profile</div>
                </div>
                <div className={cx("nav-item-children-mobile", "dark-theme")}>
                  <div style={{ display: "flex" }}>
                    <img src={IconDarkLightTheme} alt="Dark theme" className={cx("icon")} />
                    <div>Dark theme</div>
                  </div>
                  <div
                    onClick={toggleMode}
                    className={cx(
                      "darkmode-toggle",
                      activeThemeId === themeIds.DARK
                        ? "darkmode-toggle-dark"
                        : "darkmode-toggle-light"
                    )}
                  >
                    <div
                      className={cx(
                        activeThemeId === themeIds.DARK && "darkmode-toggle-button-active",
                        "darkmode-toggle-button"
                      )}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className={cx("footer-navbar-dropdown-wrapper")}>
              <div className={cx("divider")} />
              <div className={cx("footer-navbar-dropdown")}>
                <a target="_blank" href="https://blog.airight.io/" rel="noreferrer">
                  <img src={IconMedium} alt="" />
                </a>

                <a target="_blank" href="https://t.me/airight_io" rel="noreferrer">
                  <img src={IconTelegram} alt="" />
                </a>

                <a target="_blank" href="https://twitter.com/aiRight_io" rel="noreferrer">
                  <img src={IconTwitter} alt="" />
                </a>

                <a target="_blank" href="https://github.com/oraichain" rel="noreferrer">
                  <img src={IconGithub} alt="" />
                </a>

                <a
                  target="_blank"
                  href="https://www.reddit.com/r/Oraichain_Official"
                  rel="noreferrer"
                >
                  <img src={IconReddit} alt="" />
                </a>

                <a
                  target="_blank"
                  href="https://youtube.com/channel/UCyckcs_Fm8kU4o2Y1_KPjXg"
                  rel="noreferrer"
                >
                  <img src={IconYoutube} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div
            ref={mobileWalletRef}
            className={cx(
              "mobile-wallet-dropdown",
              mobileWalletInforDropdown ? "mobile-wallet-dropdown-active" : ""
            )}
          >
            <MobileDropdownProfile
              setMobileWalletInforDropdown={setMobileWalletInforDropdown}
              walletAddress={walletAddress}
              erc20Address={erc20Address}
              oraiAddress={publicAddress}
              isErc20={isErc20}
              onConnectWallet={hideDropdown}
              deactivate={deactivate}
              setOpenApproveDialog={setOpenApproveDialog}
              setOpenVerifyDialog={setOpenVerifyDialog}
              dataPriceBNB={dataPriceBNB}
            />
          </div>
        </>
      ) : (
        // <Navbar fixed='top' expand='xl' className={cx("navbar", showStake ? "navbar-staked" : "")}>
        <Navbar fixed="top" expand="xl" className={cx("navbar")}>
          <Container>
            <Navbar.Brand className="navbar-brand" as={Link} to="/">
              <img height={50} className={cx("navbar-brand-image")} src={logo} alt="AI Right" />
            </Navbar.Brand>

            <Navbar.Collapse className={cx("")} id="basic-navbar-nav">
              <Nav className={`align-items-center ${cx("navbar-dropdown")}`}>
                <NavLinks onItemClick={hideDropdown} current={history.location.pathname} />
              </Nav>
            </Navbar.Collapse>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto"
              }}
            >
              {renderNotifications()}
              <ConnectWalletButton
                walletAddress={walletAddress}
                erc20Address={erc20Address}
                oraiAddress={publicAddress}
                isErc20={isErc20}
                onConnectWallet={hideDropdown}
                deactivate={deactivate}
                setMobileWalletInforDropdown={setMobileWalletInforDropdown}
                setOpenVerifyDialog={setOpenVerifyDialog}
                setOpenApproveDialog={setOpenApproveDialog}
                dataPriceBNB={dataPriceBNB}
              />
            </div>
          </Container>
        </Navbar>
      )}
      {openVerifyDialog && <VerifyWalletDialog setOpenVerifyDialog={setOpenVerifyDialog} />}
      {openApproveDialog && <MarketApproveDialog setOpenApproveDialog={setOpenApproveDialog} />}
    </>
  );
};

export default Header;
