import React from "react";

const SearchIcon: React.FC<{
    className?: string;
}> = ({ className }) => {
    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0)">
                <path
                    d="M15.6703 11.5286L10.0177 2.06901C9.58597 1.35579 8.83525 0.924072 8 0.924072C7.16475 0.924072 6.414 1.35579 5.98228 2.06901L0.329673 11.5286C-0.101952 12.27 -0.111359 13.1522 0.310955 13.8936C0.733237 14.6349 1.49333 15.076 2.34736 15.076H13.6526C14.5066 15.076 15.2667 14.6349 15.689 13.8936C16.1114 13.1522 16.102 12.27 15.6703 11.5286Z"
                    fill="#F3574F"
                />
                <path
                    d="M15.689 13.8933C15.2668 14.6346 14.5067 15.0757 13.6526 15.0757H8V0.923828C8.83522 0.923828 9.586 1.35555 10.0177 2.06877L15.6703 11.5284C16.102 12.2698 16.1114 13.1519 15.689 13.8933Z"
                    fill="url(#paint0_linear)"
                />
                <path
                    d="M2.34719 14.1376C1.82756 14.1376 1.38309 13.8792 1.12647 13.4292C0.869875 12.9788 0.874437 12.4578 1.14113 12.0005L6.78848 2.54852C7.04692 2.12146 7.50148 1.86255 8.00001 1.86255C8.49855 1.86255 8.95314 2.12142 9.21523 2.55496L14.8635 12.0078C15.1257 12.4578 15.1302 12.9788 14.8736 13.4292C14.617 13.8792 14.1725 14.1376 13.6529 14.1376H2.34719V14.1376Z"
                    fill="#F3574F"
                />
                <path
                    d="M8.93843 4.75292V8.50674C8.93843 9.0229 8.51612 9.44518 7.99999 9.44518C7.48387 9.44518 7.06152 9.02287 7.06152 8.50674V4.75292C7.06152 4.23677 7.48384 3.81445 7.99999 3.81445C8.51615 3.81445 8.93843 4.23677 8.93843 4.75292Z"
                    fill="white"
                />
                <path
                    d="M8.93844 4.75292V8.50674C8.93844 9.0229 8.51613 9.44518 8 9.44518V3.81445C8.51613 3.81445 8.93844 4.23677 8.93844 4.75292Z"
                    fill="white"
                />
                <path
                    d="M7.99949 10.3838C7.22055 10.3838 6.5918 11.0125 6.5918 11.7915C6.5918 12.5704 7.22052 13.1992 7.99949 13.1992C8.77846 13.1992 9.40718 12.5704 9.40718 11.7915C9.40718 11.0125 8.77839 10.3838 7.99949 10.3838Z"
                    fill="white"
                />
                <path
                    d="M9.40769 11.7914C9.40769 12.5703 8.77897 13.1991 8 13.1991V10.3838C8.77891 10.3838 9.40769 11.0125 9.40769 11.7914Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="4"
                    y1="7.99978"
                    x2="16.1253"
                    y2="14.8541"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FC3232" />
                    <stop offset="1" stop-color="#B30081" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SearchIcon;
