import moment from "moment";
import { formatFloat } from "src/utils/number";

export const formatDateTime = (inputString) => {
    const m = moment(inputString);
    return m.format("YYYY-MM-DD HH:mm:ss");
};

export const formatDate = (inputString) => {
    const m = moment(inputString);
    return m.format("YYYY-MM-DD");
};

export const getDurationFromNow = (endDate, format) => {
    const now = moment(new Date()); //todays date
    const end = moment(endDate); // another date
    let duration = moment.duration(end.diff(now));
    switch (format) {
        case "months":
            duration = duration.asMonths();
            break;
        case "days":
            duration = duration.asDays();
            break;
        default:
            break;
    }
    duration = formatFloat(duration, 1);
    return duration;
};

export const formatDurationAsMonthsAndDays = (days) => {
    if (days < 30) {
        return Math.round(days) + " days";
    } else {
        const remainDays = days % 30;
        const months = Math.floor(days / 30);
        if (remainDays === 0) {
            return months + " months";
        } else {
            return months + " months and " + remainDays + " days";
        }
    }
};

export const getDurationAsTimeFromNow = (startDate) => {
    const now = moment(new Date()); //todays date
    const end = moment(startDate); // another date
    let duration = moment.duration(now.diff(end)).asSeconds();
    let days = Math.floor(duration / (3600 * 24));

    let hours = Math.floor((duration % (60 * 60 * 24)) / (60 * 60));

    let minutes = Math.floor((duration % (60 * 60)) / 60);

    let seconds = Math.floor(duration % 60);

    if (days > 0) {
        return `${days} days ago`;
    }

    if (hours > 0) {
        return `${hours} hours ago`;
    }
    if (minutes > 0) {
        return `${minutes} minutes ago`;
    }
    if (seconds > 0) {
        return `${seconds} seconds ago`;
    }

    if (seconds === 0) {
        return `Just now`;
    }

    return null;
};

export const calculateDateFromNow = (amount, unit) => {
    return moment().add(unit, amount).toDate();
};

export const assetDateReportSimilar = (item) => {
    var time = item?.creation_time ?? item?.timestamp ?? item?.mint_time
    if (!time) {
        return 'N/A';
    }
    if (!isNaN(time)) {
        if (time.toString().length <= 10) {
            time *= 1000;
        }
    }
    return moment(time).format("YYYY/MM/DD HH:mm:ss");
};

export const isStringDate = (dateStr) => {
    if (!dateStr) {
        return false;
    }
    if (typeof dateStr === 'object' && dateStr.constructor.name === 'Moment') {
        return dateStr;
    }
    if (typeof dateStr !== 'string') {
        return false;
    }
    if (
        dateStr.match(/\d{4}-\d{1,2}-\d{1,2}(\s\d{1,2}:\d{1,2}:\d{1,2})?$/g) &&
        moment(dateStr).isValid()
    ) {
        return moment(dateStr);
    }
    return false;
}
