import React from "react";

const MediumIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            
                <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.0034663 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0V0ZM13 4.20909L12.2018 4.97182C12.1677 4.99707 12.1412 5.03119 12.1252 5.07047C12.1092 5.10974 12.1042 5.15266 12.1109 5.19455V10.8064C12.1043 10.8481 12.1093 10.8909 12.1253 10.93C12.1414 10.9691 12.1678 11.0031 12.2018 11.0282L12.9809 11.7918V11.9582H9.06V11.7909L9.86819 11.0091C9.94637 10.9309 9.94637 10.9064 9.94637 10.7864V6.25273L7.7 11.9436H7.39546L4.78273 6.25273V10.0709C4.77163 10.15 4.77889 10.2306 4.80395 10.3064C4.82902 10.3823 4.87122 10.4513 4.92728 10.5082L5.97728 11.7809V11.9482H3V11.7836L4.05 10.5109C4.10597 10.453 4.14717 10.3824 4.17011 10.3052C4.19305 10.228 4.19707 10.1464 4.18182 10.0673V5.65727C4.1875 5.5968 4.17903 5.53584 4.1571 5.4792C4.13517 5.42256 4.10038 5.37179 4.05546 5.33091L3.12637 4.20909V4.04182H6.02455L8.26546 8.94545L10.2355 4.04182H13V4.20909Z" fill="#D7D7D7" />
            
        </svg>
    );
};

export default MediumIcon;
