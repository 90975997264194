import React from "react";

const ActiveNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1 6.69783L4.1746 9.89783L11 1.89783'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default ActiveNotificationIcon;
