import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames/bind';
import { Modal, Button, Tooltip } from 'antd';
import 'antd/dist/antd.css';
import { AiFillInfoCircle } from 'react-icons/ai';
import { formatCurrency } from 'src/utils/currency';
import IconWallet from 'src/assets/icons/Checkout/IconWallet.svg';
import IconTag from 'src/assets/icons/IconTag.svg';
import IconAI from 'src/assets/icons/Checkout/logoAI.svg';
import AvatarPlaceholder from 'src/components/common/AvatarPlaceholder/AvatarPlaceholder';
import CenterEllipsis from 'src/components/common/CenterEllipsis/CenterEllipsis';
import NftContext from '../NftContext/NftContext';
import ItemPriceRecommend from './ItemPriceReco';
import { currencyFormatter } from 'src/utils/currency';
import IconCheckAccount from 'src/assets/icons/CheckAccount.svg';
import { IsVerifiedArtist } from 'src/utils/checkWallet';
//theme light dark
import { useSelector } from 'react-redux';
import { selectThemeId } from 'src/store/slices/activeThemeSlice';
import { themeIds } from 'src/consts/theme';
//service
import { postPayment } from './service/PaymentService';
//styles
import styles from './index.module.scss';
const cx = cn.bind(styles);

//contants
const ORAI_RECOMMEND = [1, 10, 20, 50, 100];
const FEE = 4;

const Payment: React.FC<{}> = () => {
    const { id } = useParams<any>();
    //Nft context
    const { contextData, contextAction } = useContext(NftContext);
    //use Theme
    const activeThemeId = useSelector(selectThemeId);
    const isDarkTheme = activeThemeId === themeIds.DARK;
    //origin state
    const [loading, setLoading] = useState(false);
    const [valuePrice, setValuePrice] = useState(10);
    const [itemPriceReco, setItemPriceRec] = useState(null);
    const [textWarning, setTextWarning] = useState(false);
    const [valueDolar, setValueDolar] = useState(contextAction.OraiToDolar({ orai: valuePrice, isMicro: false }));

    //set value dolar
    useEffect(() => {
        setValueDolar(contextAction.OraiToDolar({ orai: valuePrice, isMicro: false }));
    }, [valuePrice]);

    //onChange input price
    const onChangePrice = (evt: any) => {
        const amount = evt.target.value.replace(/[^0-9]/g, '');

        if (parseInt(amount) > 100) {
            setTextWarning(true);
            return;
        } else {
            setTextWarning(false);
        }

        setValuePrice(amount !== '' ? parseInt(amount) : 0);
    };

    //handle submit
    const handleSubmit = async () => {
        if (valueDolar < 0.01) return;
        setLoading(true);

        //save in storage to top up success
        if (id) {
            localStorage.setItem('TopupNftId', id);
        } else if (!window.location.search) {
            localStorage.removeItem('TopupNftId');
        }

        const [response, err]: any = await postPayment({
            oraiAmount: valuePrice
        });
        if (response) {
            setLoading(false);
            window.open(response?.url, '_blank');
        }
        if (err) {
            console.log(err);
        }
        setLoading(false);
    };

    //caculator Fee
    const caculatorFee = () => {
        const dolar = valueDolar.toString()?.replace(',', '');
        if (!dolar) {
            return 0;
        }
        return formatCurrency((parseFloat(dolar) * FEE) / 100, 2);
    };

    //caculator total
    const caculatorTotal = () => {
        const dolar = valueDolar.toString()?.replace(',', '');
        if (!dolar) {
            return 0;
        }
        return formatCurrency((parseFloat(dolar) * FEE) / 100 + parseFloat(dolar), 2);
    };

    //handleBack
    const handleBack = () => {
        if (contextData.typeTopUp === 'buy-nft') {
            contextAction.setVisibleTopUp(false);
            contextAction.setVisibleBuyOrai(true);
        }
        if (contextData.typeTopUp === 'top-up') {
            contextAction.setVisibleTopUp(false);
            contextAction.setVisibleSelectTopUp(true);
        }
    };

    return (
        <Modal
            visible={contextData.visibleTopUp}
            width={560}
            footer={false}
            onCancel={() => {
                contextAction.setVisibleTopUp(false);
            }}
            title='Wallet top up'
        >
            <div className={cx('wrap-payment')}>
                <div className={cx('title')}>
                    <img src={IconWallet} alt='' />
                    Oraichain Wallet
                </div>
                <div className={cx('item')}>
                    <div className={cx('left')}>
                        <div className={cx('box-user')}>
                            <div className={cx('avatar')}>
                                <AvatarPlaceholder
                                    address={contextData?.wallet?.publicAddress}
                                    alt='owner-avatar'
                                    src={contextData?.wallet?.info?.picture}
                                />
                            </div>
                            <div className={cx('info')}>
                                <div className={cx('address')}>
                                    <CenterEllipsis size={5} text={contextData?.wallet?.publicAddress} />
                                </div>
                                <div className={cx('name')}>
                                    {contextData?.wallet?.info?.username}{' '}
                                    {IsVerifiedArtist(contextData?.wallet?.publicAddress) && <img src={IconCheckAccount} alt='' />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx('right')}>
                        <div className={cx('info')}>
                            <span>Your balance</span>
                            <p>
                                {currencyFormatter(contextData?.wallet?.balance)} ORAI
                                <img src={IconAI} alt='' />
                            </p>
                        </div>
                    </div>
                </div>
                <div className={cx('box-amount')}>
                    <div className={cx('title')}>
                        <img src={IconTag} alt='' />
                        Enter top up amount
                    </div>
                    <div className={cx('box-input')}>
                        <input onChange={onChangePrice} value={valuePrice} type='text' />
                        <div className={cx('info')}>
                            ${valueDolar}
                            <img src={IconAI} alt='' />
                            <span>ORAI</span>
                        </div>
                    </div>
                    {valueDolar < 0.01 && (
                        <div className={cx('text-err')}>
                            You must enter a positive integer and the top up value must be greater than $0.01
                        </div>
                    )}
                    {textWarning && <div className={cx('text-err')}>You can buy max 100 ORAI</div>}
                    <div className={cx('list-price')}>
                        {ORAI_RECOMMEND.map((item: any) => (
                            <ItemPriceRecommend
                                item={item}
                                itemPriceReco={itemPriceReco}
                                setItemPriceReco={setItemPriceRec}
                                setValuePrice={setValuePrice}
                            />
                        ))}
                    </div>
                </div>
                <div className={cx('box-total')}>
                    <div className={cx('fee')}>
                        <div className={cx('f-title')}>
                            Fee <span>({FEE}%)</span>{' '}
                            <Tooltip
                                placement='right'
                                title='This fee is charged for executing the transaction and covering the risk of slippage.'
                            >
                                <AiFillInfoCircle />
                            </Tooltip>
                        </div>
                        <div className={cx('f-txt')}>${caculatorFee()}</div>
                    </div>
                    <div className={cx('total')}>
                        <div className={cx('f-title')}>Total</div>
                        <div className={cx('f-txt')}>${caculatorTotal()}</div>
                    </div>
                </div>
                <div className={cx('box-btn')}>
                    <button onClick={handleBack} className={'back'} style={{ background: isDarkTheme ? '#1e1e21' : '#E7EAF2' }}>
                        Back
                    </button>
                    <Button loading={loading} onClick={handleSubmit} className={cx(valueDolar < 0.01 ? 'disable' : '')}>
                        Checkout
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default Payment;
