import numeral from "numeral";

export const formatFloat = (input, digitsAfterDecimalPoint = 1) => {
    if (isNaN(input)) {
        return null;
    }
    const value = parseFloat(input);
    if (numeral(value).format("0,0." + "0".repeat(digitsAfterDecimalPoint)) + "" === "NaN") {
        return value?.toFixed(9).replace(/\.?0+$/, "");
    }
    return numeral(value).format("0,0." + "0".repeat(digitsAfterDecimalPoint));
};

export const floorFloat = (input, digitsAfterDecimalPoint = 1) => {
    if (isNaN(input)) {
        return null;
    }

    const value = parseFloat(input);
    const scale = parseInt(1 + "0".repeat(digitsAfterDecimalPoint));
    return Math.floor(value * scale) / scale;
};

export const roundFloat = (input, digitsAfterDecimalPoint = 1) => {
    if (isNaN(input)) {
        return null;
    }

    const value = parseFloat(input);
    const scale = parseInt(1 + "0".repeat(digitsAfterDecimalPoint));
    return Math.round(value * scale) / scale;
};

export const ceilFloat = (input, digitsAfterDecimalPoint = 1) => {
    if (isNaN(input)) {
        return null;
    }

    const value = parseFloat(input);
    const scale = parseInt(1 + "0".repeat(digitsAfterDecimalPoint));
    return Math.ceil(value * scale) / scale;
};

export const numberToShortString = (value) => {
    if (!value) return value;
    if (value < 1000) return value.toFixed(2);
    value = value.toFixed(0);
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + value).length / 3);
    if (("" + value).length % 3 == 0) suffixNum -= 1;
    var shortValue = parseFloat(suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value);
    if (shortValue % 1 != 0) {
        shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
};
