import React from "react";

const OraiPriceNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29375 14.5029V15.9998C3.42187 15.6623 0.3375 12.5779 0 8.70605H1.49688C1.825 11.7545 4.24531 14.1748 7.29375 14.5029Z" fill="#00AEEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9998 8.70605C15.6623 12.5779 12.5779 15.6623 8.70605 15.9998V14.5029C11.7545 14.1764 14.1764 11.7545 14.5029 8.70605H15.9998Z" fill="#00AEEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.29375 0V1.49688C4.24531 1.82344 1.82344 4.24531 1.49688 7.29375H0C0.3375 3.42187 3.42187 0.3375 7.29375 0Z" fill="#00AEEF"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9998 7.29375H14.5029C14.1764 4.24531 11.7545 1.82344 8.70605 1.49688V0C12.5779 0.3375 15.6623 3.42187 15.9998 7.29375Z" fill="#00AEEF"/>
      <path d="M11.5157 4.65166L11.5142 6.4876V6.80635L11.5126 7.76416V8.08291L11.511 9.04229V9.36103L11.5095 11.3548C11.5095 11.4423 11.4376 11.5142 11.3485 11.5142L9.43447 11.5126H9.11416L8.15635 11.511H7.83604L6.87822 11.5095H6.55791L4.64385 11.5079C4.55479 11.5079 4.48291 11.436 4.48291 11.347L4.48447 9.3501V9.03135L4.48604 8.07354V7.75479L4.4876 6.79541V6.47666L4.48916 4.64229C4.48916 4.55322 4.56104 4.48291 4.6501 4.48291L6.56416 4.48447H6.88447L7.84229 4.48604H8.1626L9.12041 4.4876H9.43916L11.3532 4.48916C11.4438 4.49229 11.5157 4.56416 11.5157 4.65166Z" fill="#1B70F0"/>
      <path d="M7.87187 7.17344C7.71562 7.01094 7.5 6.92969 7.22656 6.92969C6.95469 6.92969 6.74063 7.01094 6.58125 7.17188C6.42344 7.33125 6.34531 7.5625 6.34531 7.86562L6.34375 9.06875H6.56094V8.44531L7.8875 8.44687V9.07031H8.10781L8.10938 7.86719C8.10938 7.56406 8.03125 7.33281 7.87187 7.17344ZM7.88906 8.25156H6.5625V7.84531C6.5625 7.60781 6.62188 7.43281 6.7375 7.3125C6.85469 7.19219 7.01719 7.13125 7.22656 7.13125C7.43594 7.13125 7.59844 7.19219 7.71562 7.31406C7.83125 7.43281 7.89062 7.61094 7.89062 7.84844V8.25156H7.88906Z" fill="white"/>
      <path d="M9.22812 7.125L9.22656 8.87344L9.65469 8.875V9.07031L8.57812 9.06875V8.87344H9.00625L9.00781 7.125H8.57969V6.92969L9.65625 6.93125V7.12656L9.22812 7.125Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79688 6.75C4.79688 6.83594 4.72656 6.90625 4.64062 6.90625H4.01562C3.92969 6.90625 3.85938 6.8375 3.85938 6.75156V6.75C3.85938 6.66406 3.92969 6.59375 4.01562 6.59375H4.64062C4.72656 6.59375 4.79688 6.66406 4.79688 6.75Z" fill="url(#paint0_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10938 7.9999C5.10938 8.08584 5.03906 8.15459 4.95312 8.15615H4.48438H4.01562C3.92969 8.15615 3.85938 8.08584 3.85938 8.00146C3.85938 7.91553 3.92969 7.84521 4.01562 7.84521H4.48438H4.95312C5.03906 7.84521 5.10938 7.91396 5.10938 7.9999Z" fill="url(#paint1_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.95312 9.25469C4.95 9.3375 4.88437 9.40312 4.80156 9.40625H4.4875H4.01719C3.93125 9.40625 3.86094 9.33906 3.85938 9.25313V9.25C3.85938 9.16406 3.92969 9.09375 4.01719 9.09375H4.80156C4.8875 9.09688 4.95625 9.16875 4.95312 9.25469Z" fill="url(#paint2_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1406 9.24844V9.25C12.1406 9.33594 12.0703 9.40625 11.9844 9.40625H11.3594C11.2734 9.40625 11.2031 9.33594 11.2031 9.25C11.2031 9.16406 11.2734 9.09375 11.3594 9.09375H11.9844C12.0703 9.09375 12.1406 9.1625 12.1406 9.24844Z" fill="url(#paint3_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1406 8C12.1406 8.08594 12.0703 8.15625 11.9844 8.15625H11.5156H11.0469C10.9609 8.15625 10.8906 8.08594 10.8906 8C10.8906 7.91406 10.9609 7.84375 11.0469 7.84375H11.5156H11.9844C12.0703 7.84375 12.1406 7.91406 12.1406 8Z" fill="url(#paint4_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1406 6.74844C12.1406 6.83594 12.0703 6.90625 11.9844 6.90625H11.5156H11.2031C11.1172 6.90625 11.0469 6.83594 11.0469 6.75C11.0469 6.66406 11.1172 6.59375 11.2031 6.59375H11.5156H11.9844C12.0703 6.59375 12.1406 6.6625 12.1406 6.74844Z" fill="url(#paint5_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.88281 4.01562V4.48281V4.94999C6.88593 5.03593 6.81718 5.10781 6.73125 5.10937C6.64531 5.11249 6.57344 5.04531 6.57031 4.95937C6.57031 4.95624 6.57031 4.95312 6.57031 4.94999V4.48281V4.01562C6.57031 3.92969 6.64062 3.85938 6.72656 3.85938C6.8125 3.85938 6.88281 3.92969 6.88281 4.01562Z" fill="url(#paint6_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16406 4.02666C8.16406 4.02666 8.16406 4.02822 8.16406 4.02666V4.53135V4.78447C8.16406 4.87666 8.09375 4.95322 8.00781 4.95322C7.92188 4.95322 7.85156 4.87822 7.85156 4.78447V4.02666C7.85156 3.93447 7.92188 3.85791 8.00781 3.85791H8.00937C8.09375 3.85947 8.16406 3.93447 8.16406 4.02666Z" fill="url(#paint7_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42971 4.01406V4.63593C9.43283 4.72187 9.36408 4.79374 9.27814 4.7953C9.19219 4.79843 9.12031 4.73124 9.11719 4.6453C9.11719 4.64218 9.11719 4.63906 9.11719 4.63593V4.01406C9.11719 3.92812 9.1875 3.85938 9.27345 3.85938C9.35939 3.85938 9.42971 3.92812 9.42971 4.01406Z" fill="url(#paint8_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.89062 11.0469V11.5156V11.9844C6.89062 12.0703 6.82031 12.1406 6.73438 12.1406C6.64844 12.1406 6.57812 12.0703 6.57812 11.9844V11.5156V11.0469C6.57812 10.9609 6.64844 10.8906 6.73438 10.8906C6.82031 10.8906 6.89062 10.9609 6.89062 11.0469Z" fill="url(#paint9_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1719 11.2142V11.5501V11.9688C8.17502 12.061 8.10627 12.1376 8.02032 12.1407C7.93438 12.1438 7.8625 12.072 7.85938 11.9782C7.85938 11.9751 7.85938 11.9704 7.85938 11.9673V11.2126C7.85938 11.1204 7.92969 11.0454 8.01564 11.0454H8.0172C8.10158 11.047 8.1719 11.122 8.1719 11.2142Z" fill="url(#paint10_linear_9552_97440)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.42188 11.3594V11.9844C9.42188 12.0703 9.35156 12.1406 9.26562 12.1406C9.17969 12.1406 9.10938 12.0703 9.10938 11.9844V11.3594C9.10938 11.2734 9.17969 11.2031 9.26562 11.2031C9.35156 11.2031 9.42188 11.2734 9.42188 11.3594Z" fill="url(#paint11_linear_9552_97440)"/>
      <defs>
      <linearGradient id="paint0_linear_9552_97440" x1="3.9781" y1="6.75" x2="4.91049" y2="6.75" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_9552_97440" x1="3.99461" y1="7.9999" x2="5.238" y2="7.9999" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_9552_97440" x1="3.98354" y1="9.25" x2="5.076" y2="9.25" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      <linearGradient id="paint3_linear_9552_97440" x1="11.1119" y1="9.25" x2="12.0444" y2="9.25" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint4_linear_9552_97440" x1="10.7423" y1="8" x2="12.0099" y2="8" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint5_linear_9552_97440" x1="10.9333" y1="6.75" x2="12.0214" y2="6.75" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint6_linear_9552_97440" x1="6.72656" y1="5.1193" x2="6.72656" y2="3.87987" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint7_linear_9552_97440" x1="8.00781" y1="4.98316" x2="8.00781" y2="3.89448" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint8_linear_9552_97440" x1="9.27345" y1="4.82176" x2="9.27345" y2="3.89372" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9ECBFF"/>
      <stop offset="1" stop-color="#F0F7FF"/>
      </linearGradient>
      <linearGradient id="paint9_linear_9552_97440" x1="6.73438" y1="12.1526" x2="6.73438" y2="10.9088" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      <linearGradient id="paint10_linear_9552_97440" x1="8.01564" y1="12.1542" x2="8.01564" y2="11.0497" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      <linearGradient id="paint11_linear_9552_97440" x1="9.26562" y1="12.1695" x2="9.26562" y2="11.2183" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F0F7FF"/>
      <stop offset="1" stop-color="#9ECBFF"/>
      </linearGradient>
      </defs>
      </svg>      
    );
};

export default OraiPriceNotificationIcon;