import React from "react";

const AiriIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_13371_68470)'>
                <path
                    d='M12.2 8.325C11.8438 10.5688 10.075 12.3438 7.825 12.7V14.5C11.0562 14.125 13.625 11.5562 14 8.325H12.2ZM1.8 8.325H0C0.375 11.5562 2.9375 14.125 6.16875 14.5V12.7C3.925 12.3438 2.15625 10.5688 1.8 8.325ZM7.825 0.5V2.3C10.0688 2.65625 11.8438 4.43125 12.2 6.675H14C13.625 3.44375 11.0562 0.875 7.825 0.5ZM0 6.675H1.8C2.15625 4.43125 3.925 2.65625 6.16875 2.3V0.5C2.9375 0.875 0.375 3.44375 0 6.675Z'
                    fill='url(#paint0_linear_13371_68470)'
                />
                <path
                    d='M7.00002 3.21289L5.74377 5.38789L7.00002 7.55664L8.26252 9.73164H10.7625L7.00002 3.21289Z'
                    fill='url(#paint1_linear_13371_68470)'
                />
                <path d='M4.48745 7.56289L3.2312 9.73164H5.73745L6.99995 7.55664' fill='url(#paint2_linear_13371_68470)' />
                <path d='M7.82495 14.5C8.87495 14.375 9.88745 14.0188 10.7812 13.4438L9.01245 13L7.82495 14.5Z' fill='#0E4ADB' />
                <path
                    d='M12.4938 9.51836L12.9375 11.2871C13.5125 10.3934 13.875 9.37461 13.9938 8.31836L12.4938 9.51836Z'
                    fill='#0E4ADB'
                />
                <path d='M11.4 10.3941L12.9438 11.2941L12.5 9.52539L11.4 10.3941Z' fill='#105EE6' />
                <path d='M7.82495 12.7002V14.5002L9.01245 13.0002L7.82495 12.7002Z' fill='#105EE6' />
                <path d='M12.4937 9.51895L13.9937 8.3252H12.1937L12.4937 9.51895Z' fill='#105EE6' />
                <path d='M9.87495 11.9062L9.01245 13L10.7812 13.4438V13.4375L9.87495 11.9062Z' fill='#105EE6' />
                <path d='M11.4 10.3939L12.5 9.51895L12.2 8.3252C12.0813 9.0627 11.8063 9.76895 11.4 10.3939Z' fill='#3565FD' />
                <path d='M9.01245 13L9.87495 11.9062C9.2562 12.3125 8.5562 12.5812 7.82495 12.7L9.01245 13Z' fill='#3565FD' />
                <path
                    d='M12.9375 11.2871L11.1375 11.6496L10.7812 13.4371C11.65 12.8871 12.3875 12.1559 12.9375 11.2871Z'
                    fill='#0044E3'
                />
                <path d='M11.1438 11.6502L12.9438 11.2877L11.4 10.3877H11.3938L11.1438 11.6502Z' fill='#0E51ED' />
                <path d='M10.7812 13.4438L11.1375 11.6562L9.875 11.9125L10.7812 13.4438Z' fill='#0E51ED' />
                <path
                    d='M11.1437 11.6498L11.3937 10.3936H11.4C11 10.9998 10.4875 11.5123 9.88123 11.9061L11.1437 11.6498Z'
                    fill='#1D63F0'
                />
                <path d='M8.24377 9.73145L8.25002 9.7502L8.26252 9.73145H8.24377Z' fill='white' />
                <path d='M7.82495 0.5C8.87495 0.625 9.88745 0.98125 10.7812 1.55625L9.01245 2L7.82495 0.5Z' fill='#2BB7FD' />
                <path
                    d='M12.4938 5.48164L12.9375 3.71289C13.5125 4.60664 13.875 5.62539 13.9938 6.68164L12.4938 5.48164Z'
                    fill='#3564FD'
                />
                <path d='M11.4 4.60664L12.9375 3.71289L12.4938 5.48164L11.4 4.60664Z' fill='#3F7CFD' />
                <path d='M7.82495 2.3V0.5L9.01245 2L7.82495 2.3Z' fill='#68C6FD' />
                <path d='M12.4937 5.48145L13.9937 6.6752H12.1937L12.4937 5.48145Z' fill='#105EE6' />
                <path d='M9.87495 3.09414L9.01245 2.00039L10.7812 1.55664L9.87495 3.09414Z' fill='#35ADFD' />
                <path d='M11.4 4.60645L12.5 5.48145L12.2 6.6752C12.0813 5.9377 11.8063 5.23145 11.4 4.60645Z' fill='#3A71FD' />
                <path d='M9.01245 2L9.87495 3.09375C9.2562 2.6875 8.5562 2.41875 7.82495 2.3L9.01245 2Z' fill='#30B5FD' />
                <path d='M12.9375 3.7125L11.1375 3.35L10.7812 1.5625C11.65 2.10625 12.3875 2.84375 12.9375 3.7125Z' fill='#3A92FD' />
                <path d='M11.1438 3.34961L12.9438 3.71211L11.4 4.61211H11.3938L11.1438 3.34961Z' fill='#3F88FD' />
                <path d='M10.7812 1.55664L11.1375 3.34414L9.875 3.09414L10.7812 1.55664Z' fill='#389EFD' />
                <path d='M11.1437 3.35L11.3937 4.60625H11.4C11 4 10.4875 3.4875 9.88123 3.09375L11.1437 3.35Z' fill='#2893FD' />
                <path d='M6.175 14.5C5.125 14.375 4.1125 14.0188 3.21875 13.4438L4.9875 13L6.175 14.5Z' fill='#3564FD' />
                <path d='M1.5 9.51895L1.05625 11.2877C0.4875 10.3939 0.125 9.38145 0 8.3252L1.5 9.51895Z' fill='#2BB7FD' />
                <path d='M2.6 10.3936L1.0625 11.2873L1.50625 9.51855L2.6 10.3936Z' fill='#35ADFD' />
                <path d='M6.1687 12.7002V14.5002L4.9812 13.0002L6.1687 12.7002Z' fill='#105EE6' />
                <path d='M1.5 9.51895L0 8.3252H1.8L1.5 9.51895Z' fill='#68C6FD' />
                <path d='M4.125 11.9062L4.9875 13L3.21875 13.4438V13.4375L4.125 11.9062Z' fill='#3F7CFD' />
                <path d='M2.6 10.3939L1.5 9.51895L1.8 8.3252C1.91875 9.0627 2.19375 9.76895 2.6 10.3939Z' fill='#30B5FD' />
                <path d='M4.9875 13L4.125 11.9062C4.74375 12.3125 5.44375 12.5812 6.175 12.7L4.9875 13Z' fill='#3F7CFD' />
                <path d='M1.0625 11.2871L2.8625 11.6496L3.21875 13.4371C2.35 12.8871 1.6125 12.1559 1.0625 11.2871Z' fill='#3A92FD' />
                <path d='M2.85625 11.6502L1.0625 11.2877L2.60625 10.3877H2.6125L2.85625 11.6502Z' fill='#379DFC' />
                <path d='M3.21245 13.4438L2.8562 11.6562L4.1187 11.9125L3.21245 13.4438Z' fill='#3F88FD' />
                <path
                    d='M2.85623 11.6498L2.60623 10.3936H2.59998C2.99998 10.9998 3.51248 11.5123 4.11873 11.9061L2.85623 11.6498Z'
                    fill='#2893FD'
                />
                <path d='M6.175 0.5C5.125 0.625 4.1125 0.98125 3.21875 1.55625L4.9875 2L6.175 0.5Z' fill='#84E9FD' />
                <path d='M1.5 5.48164L1.05625 3.71289C0.4875 4.60664 0.125 5.61914 0 6.67539L1.5 5.48164Z' fill='#84E9FD' />
                <path d='M2.6 4.60664L1.0625 3.71289L1.5 5.48164L2.6 4.60664Z' fill='#3AD2FD' />
                <path d='M6.1687 2.3V0.5L4.9812 2L6.1687 2.3Z' fill='#49CDFD' />
                <path d='M1.5 5.48145L0 6.6752H1.8L1.5 5.48145Z' fill='#49CDFD' />
                <path d='M4.125 3.09355L4.9875 1.9998L3.21875 1.5498L4.125 3.09355Z' fill='#3AD2FD' />
                <path d='M2.6 4.60645L1.5 5.48145L1.8 6.6752C1.91875 5.9377 2.19375 5.23145 2.6 4.60645Z' fill='#17C7FD' />
                <path d='M4.9875 2L4.125 3.09375C4.74375 2.6875 5.4375 2.41875 6.16875 2.3L4.9875 2Z' fill='#17C7FD' />
                <path d='M1.0625 3.7125L2.8625 3.35L3.21875 1.5625C2.35 2.10625 1.6125 2.84375 1.0625 3.7125Z' fill='#84E9FD' />
                <path d='M2.85625 3.34961L1.0625 3.71211L2.60625 4.61211H2.6125L2.85625 3.34961Z' fill='#49DFFD' />
                <path d='M3.21245 1.55664L2.8562 3.34414L4.12495 3.09414L3.21245 1.55664Z' fill='#49DFFD' />
                <path
                    d='M2.85623 3.35L2.60623 4.60625H2.59998C2.99998 4 3.51248 3.4875 4.11873 3.09375L2.85623 3.35Z'
                    fill='#3AD2FD'
                />
                <path d='M5.7312 5.38086L5.7437 5.38711L5.74995 5.38086H5.7312Z' fill='white' />
                <path d='M3.23755 9.73125H5.73755L4.48755 7.5625L3.23755 9.73125Z' fill='#2BB7FD' />
                <path d='M10.7625 9.73086L9.51245 7.56836L8.26245 9.73086H10.7625Z' fill='#3A71FD' />
                <path d='M9.51252 7.56875L6.99377 7.5625L8.24377 9.73125H8.26252L9.51252 7.56875Z' fill='#3A92FD' />
                <path d='M6.99377 7.5625H4.49377L5.74377 9.73125L6.99377 7.5625Z' fill='#49CDFD' />
                <path d='M8.25 5.38164L7 3.21289L5.75 5.38164H8.25Z' fill='#84E9FD' />
                <path d='M8.25002 5.38086L6.99377 7.56211H9.50627L8.25002 5.38086Z' fill='#30B5FD' />
                <path d='M5.75002 5.38086L5.74377 5.38711L6.99377 7.55586L8.25002 5.38086H5.75002Z' fill='#49CDFD' />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_13371_68470'
                    x1='1.97581'
                    y1='2.58173'
                    x2='12.0609'
                    y2='12.4541'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#84E9FD' />
                    <stop offset='1' stop-color='#0044E3' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_13371_68470'
                    x1='2.79822'
                    y1='1.74201'
                    x2='12.8833'
                    y2='11.6144'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#84E9FD' />
                    <stop offset='1' stop-color='#0044E3' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_13371_68470'
                    x1='0.481907'
                    y1='4.10816'
                    x2='10.567'
                    y2='13.9805'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#84E9FD' />
                    <stop offset='1' stop-color='#0044E3' />
                </linearGradient>
                <clipPath id='clip0_13371_68470'>
                    <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default AiriIcon;
