import { callApi } from "src/api";

interface postPaymentProps {
  oraiAmount: number,
};

export const postPayment = async ({ oraiAmount }: postPaymentProps) => {
  try {
      const response = await callApi({
          method: 'POST',
          url: `payment/stripe/create-checkout-session`,
          data: {
              oraiAmount,
          },
          contentType: "application/json",
      });
      return [response.data, null];
  } catch (error) {
      return [null, error];
  }
};