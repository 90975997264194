// @ts-nocheck
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  // Switch,
  Route,
  BrowserRouter as Router,
  useHistory,
  useLocation
} from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { CircularProgress } from "@material-ui/core";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "src/App.css";
import Footer from "src/components/common/Footer/Footer";
import Header from "src/components/common/Header/Header";
import { Links } from "src/components/common/Header/NavLinks";
import ComingSoon from "src/components/pages/ComingSoon/ComingSoon";
import NotFound from "src/components/pages/NotFound/NotFound";
import "src/i18n";
import store from "src/store/store";
import GlobalStyles from "./GlobalStyles";
import AlertBox from "./components/common/AlertBox/AlertBox";
import NftGalleryDetail from "./components/pages/GalleryDetails/NftGalleryDetail";
import { NftProvider } from "./components/pages/NftDetail/NftContext/NftContext";
import { pagePaths } from "./consts/pagePaths";
import { dispatchRef } from "./store/ReduxDispatcher";
import { setPrice } from "./store/slices/priceSlice";
import { useConnectKeplrWallet } from "./utils/hooks";

const ThreeDGallery = React.lazy(() => import("src/components/pages/3DGalleryDemo/3Dgallery"));
const Feed = React.lazy(() => import("src/components/pages/Feed/Feed"));
const AIModels = React.lazy(() => import("src/components/pages/AIModels/AIModels"));
const CreateAsset = React.lazy(() => import("src/components/pages/CreateAsset/CreateAsset"));
const AIModelDetails = React.lazy(
  () => import("src/components/pages/AIModelDetails/AIModelDetails")
);
const Market = React.lazy(() => import("src/components/pages/Market/Market"));
const NftStaking = React.lazy(() => import("src/components/pages/NftStaking/NftStaking"));
const MarketVersion1 = React.lazy(() => import("src/components/pages/Market/MarketVersion1"));
const Profile = React.lazy(() => import("src/components/pages/Profile/Profile"));
const NftDetail = React.lazy(() => import("src/components/pages/NftDetail/NftDetail"));
const PaymentSuccess = React.lazy(() => import("src/components/pages/PaymentSuccess"));
const PaymentError = React.lazy(() => import("src/components/pages/PaymentError"));
const BscNftDetail = React.lazy(() => import("src/components/pages/BscNftDetail/BscNftDetail"));
const AdminMintNFT = React.lazy(() => import("src/components/pages/AdminMintNFT/NftMint"));
const Landing = React.lazy(() => import("src/components/pages/Landing/Landing"));
const GalleryDetails = React.lazy(() => import("src/components/pages/GalleryDetails"));
const Collections = React.lazy(() => import("src/components/pages/Collections"));
const CreateCollection = React.lazy(() => import("src/components/pages/CreateCollection"));
const PreviewCollection = React.lazy(
  () => import("src/components/pages/CreateCollection/PreviewCollection")
);
const CollectionDetail = React.lazy(() => import("src/components/pages/CollectionDetail"));
const CollectionTradingTop = React.lazy(() => import("src/components/pages/CollectionTradingTop"));
const CollectionTradingSearch = React.lazy(
  () => import("src/components/pages/CollectionTradingTop/search")
);
const Farming = React.lazy(() => import("src/components/pages/Farming/Farming"));
const FarmingCryptia = React.lazy(() => import("src/components/pages/FarmingCryptia/Farming"));
const NftMarket = React.lazy(() => import("src/components/pages/NftMarket/NftMarket"));
const CheckingArt = React.lazy(() => import("src/components/pages/CheckingArt/CheckingArt"));
const SwapAndLiquidity = React.lazy(
  () => import("src/components/pages/SwapAndLiquidity/SwapAndLiquidity")
);
const LockLiquidity = React.lazy(
  () => import("src/components/pages/SwapAndLiquidity/LockLiquidity")
);
const Pairs = React.lazy(() => import("src/components/pages/Pairs/Pairs"));
const ViewLock = React.lazy(() => import("src/components/pages/ViewLock/ViewLock"));
const Locker = React.lazy(() => import("src/components/pages/Locker/Locker"));
const AirDrop = React.lazy(() => import("src/components/pages/AirDrop"));
const FairDropOraiX = React.lazy(() => import("src/components/pages/FairDropOraiX"));
const MediaKit = React.lazy(() => import("src/components/pages/MediaKit/MediaKit"));
const ConnectDiscordCallBack = React.lazy(
  () => import("src/components/pages/ConnectDiscordCallBack")
);
const SimilarityCheckReport = React.lazy(
  () => import("src/components/pages/SimilarityCheckReport")
);
const DemoSimilarityCheckReport = React.lazy(
  () => import("src/components/pages/DemoSimilarityCheckReport")
);
const DemoGenerateAsset = React.lazy(() => import("src/components/pages/DemoGenerateAsset"));
const TermsCondition = React.lazy(() => import("src/components/pages/TermsCondition"));
const BuyAIRI = React.lazy(() => import("src/components/pages/BuyAIRI"));
const BuyAIRIToken = React.lazy(() => import("src/components/pages/BuyAIRIToken"));
const CheckContract = React.lazy(() => import("src/components/pages/CheckContract"));
const persistor = persistStore(store);

const DelayedFallback: React.FC<{ delay?: number; text?: string }> = ({ delay = 500, text }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!show) {
    return <div style={{ height: "100vh" }} />;
  }

  return (
    <ComingSoon>
      {text} <CircularProgress />
    </ComingSoon>
  );
};

// scroll to top
const scrollTopMap = {};
const ScrollToTop: React.FC<{}> = ({ children }) => {
  const { pathname } = useLocation();
  const { action } = useHistory();
  const rootEl = document.querySelector("#root");

  useEffect(() => {
    let scrollTop = -1;
    if (action === "PUSH") {
      scrollTop = 0;
    } else if (action === "POP") {
      scrollTop = scrollTopMap[pathname] || 0;
    }
    if (scrollTop !== -1) {
      console.log("scroll to Top");
      // setImmediate(() => {
      const scrollTop = scrollTopMap[pathname] || 0;
      document.querySelector("#root").scrollTop = scrollTop;
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
      // });
    }
    const handler = (ev) => (scrollTopMap[pathname] = ev.target.scrollTop);
    rootEl?.addEventListener("scroll", handler);
    return () => rootEl?.removeEventListener("scroll", handler);
  }, [pathname]);

  const link = Links.find((item) => item.path === pathname);

  if (link) {
    document.title = link.description ?? link.title;
  }

  return children;
};

const UpdatePrice = () => {
  const dispatch = useDispatch();
  const updatePrice = async () => {
    try {
      const [oraiToUSD, airiToUSD] = await Promise.all([
        fetch(
          "https://price.market.orai.io/simple/price?ids=oraichain-token&vs_currencies=usd"
        ).then((res) => res.json()),
        fetch("https://price.market.orai.io/simple/price?ids=airight&vs_currencies=usd").then(
          (res) => res.json()
        )
      ]);

      dispatch(
        setPrice({
          orai: {
            usd: oraiToUSD?.["oraichain-token"].usd
          },
          airi: {
            usd: airiToUSD.airight.usd
          }
        })
      );
    } catch (error) {
      console.log("err", error);
    }
    setTimeout(updatePrice, 30000);
  };

  useEffect(() => {
    updatePrice();
  }, []);

  return null;
};

const KeplrListener = () => {
  const { connectKeplrWallet } = useConnectKeplrWallet();
  useEffect(() => {
    // add event listener here to prevent adding the same one everytime App.tsx re-renders
    window.addEventListener("keplr_keystorechange", connectKeplrWallet);

    return () => {
      window.removeEventListener("keplr_keystorechange", connectKeplrWallet);
    };
  }, []);

  return null;
};

const App: React.FC<{}> = ({}) => {
  const [specificPage, setSpecificPage] = useState(false);
  const pathname = window.location.pathname;

  useEffect(() => {
    dispatchRef.current = store?.dispatch;
  }, []);

  useEffect(() => {
    if (pathname.includes("/3d-gallery") && !specificPage) {
      setSpecificPage(true);
    } else if (!pathname.includes("/3d-gallery") && specificPage) {
      setSpecificPage(false);
    }
  }, [pathname]);

  const CommonPage = () => (
    <Router>
      <NftProvider>
        {/* <StickerAirdrop /> */}
        <Header />
        <ScrollToTop>
          <CacheSwitch>
            <CacheRoute exact path="/marketplace" component={Market} />
            <Route exact path="/feed" component={Feed} />
            <Route path="/feed/:id" component={Feed} />
            <CacheRoute exact path="/v1/marketplace" component={MarketVersion1} />
            <Route exact path="/token-farming/:domain" component={Farming} />
            <Route exact path="/token-farming/partner/cryptia" component={FarmingCryptia} />
            <Route exact path="/token-farming" component={Farming} />
            <Route exact path="/collections" component={Collections} />
            <Route exact path="/collections/:id" component={CollectionDetail} />
            <Route
              exact
              path="/collections/event/trading/search"
              component={CollectionTradingSearch}
            />
            <Route exact path="/collections/trading/top" component={CollectionTradingTop} />
            <Route exact path="/nft-staking" component={NftStaking} />
            <Route exact path="/create-collection" component={CreateCollection} />
            <Route exact path="/preview-collection" component={PreviewCollection} />
            <Route exact path="/ai-models" component={AIModels} />
            <Route exact path="/ai-models/:id" component={AIModelDetails} />
            <Route path="/ai-models/:id/create-asset" component={CreateAsset} />
            <Route path="/profile/:address" component={Profile} />
            <Route path="/nft-detail/:id" component={NftDetail} exact={true} />
            <Route path="/bsc-nft-detail/:id" component={BscNftDetail} />
            {/* <Route path="/nft-bsc-detail/:id" component={BscDetail} /> */}
            <Route exact path="/nft-gallery" component={NftMarket} />
            <Route exact path="/nft-gallery/:galleryId" component={GalleryDetails} />
            <Route path="/nft-detail/:galleryId/:id" component={NftGalleryDetail} exact={true} />
            {/* <Route path='/original-check' component={CheckingArt} /> */}
            <Route path="/admin" component={AdminMintNFT} />
            <Route path="/swap" component={SwapAndLiquidity} />
            <Route path="/airdrop" component={AirDrop} />
            {/* <Route path='/register' component={FairDropOraiX} /> */}
            {/* <Route path='/airdrop' component={AirDrop} /> */}
            <Route path={pagePaths.PAIRS} component={Pairs} />
            <Route path="/view-lock/:pairAddress" component={ViewLock} />
            <Route path="/view-lock" component={ViewLock} />
            <Route path="/lock-liquidity/:pairAddress" component={LockLiquidity} />
            <Route path="/lock-liquidity" component={LockLiquidity} />
            <Route path="/locker/:pairAddress" component={Locker} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/payment/stripe/success" component={PaymentSuccess} />
            <Route path="/payment/stripe/cancel" component={PaymentError} />
            <Route path="/media-kit" component={MediaKit} />
            <Route path="/connect-discord-callback" component={ConnectDiscordCallBack} />
            <Route
              path="/similarity-check-report/:id/:reportId"
              component={SimilarityCheckReport}
            />
            <Route path="/nft-authenticity-report/:id" component={DemoSimilarityCheckReport} />
            <Route path="/nft-content-authenticity" component={DemoGenerateAsset} />
            <Route path="/buy-airi" component={BuyAIRI} />
            <Route path="/buy-airi-token" component={BuyAIRIToken} />
            <Route path="/terms-condition" component={TermsCondition} />
            <Route path="/check-contract" component={CheckContract} />
            {/* <Route path='/buy-airi' component={TermsCondition} /> */}
            <Route path="/" component={Landing} />
          </CacheSwitch>
        </ScrollToTop>
        <Footer />
      </NftProvider>
    </Router>
  );

  const SpecificPage = () => (
    <Router>
      <CacheSwitch>
        <Route path="/3d-gallery" component={ThreeDGallery} />
        <Route path="/not-found" component={NotFound} />
      </CacheSwitch>
    </Router>
  );

  const Empty = () => {
    return <div />;
  };

  return (
    <PersistGate loading={null} persistor={persistor}>
      <UpdatePrice />
      <KeplrListener />
      <GlobalStyles />
      <Suspense fallback={<Empty />}>{specificPage ? <SpecificPage /> : <CommonPage />}</Suspense>
      <AlertBox />
      <ToastContainer />
    </PersistGate>
  );
};

export default App;
