import React from "react";

const BuyNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6 1.89783C3.24 1.89783 1 4.13783 1 6.89783C1 9.65783 3.24 11.8978 6 11.8978C8.76 11.8978 11 9.65783 11 6.89783'
                stroke='currentColor'
                stroke-width='0.8'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.9999 1.89783L6.8999 5.99783'
                stroke='currentColor'
                stroke-width='0.8'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M6.5 3.98286V6.39786H8.915'
                stroke='currentColor'
                stroke-width='0.8'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default BuyNotificationIcon;
