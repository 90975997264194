import React from "react";

const ReceiveRoyaltyNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M4.75 7.77283C4.75 8.25783 5.12501 8.64783 5.58501 8.64783H6.52499C6.92499 8.64783 7.25 8.30784 7.25 7.88284C7.25 7.42784 7.05 7.26284 6.755 7.15784L5.25 6.63284C4.955 6.52784 4.755 6.36784 4.755 5.90784C4.755 5.48784 5.08 5.14284 5.48 5.14284H6.42C6.88 5.14284 7.255 5.53284 7.255 6.01784'
                stroke='white'
                stroke-width='0.8'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M6 4.64783V9.14783' stroke='white' stroke-width='0.8' stroke-linecap='round' stroke-linejoin='round' />
            <path
                d='M11 6.89783C11 9.65783 8.76 11.8978 6 11.8978C3.24 11.8978 1 9.65783 1 6.89783C1 4.13783 3.24 1.89783 6 1.89783'
                stroke='white'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M8.5 2.39783V4.39783H10.5' stroke='white' stroke-linecap='round' stroke-linejoin='round' />
            <path d='M11 1.89783L8.5 4.39783' stroke='white' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    );
};

export default ReceiveRoyaltyNotificationIcon;
