import React, { useMemo, useCallback, createElement, cloneElement } from "react";
import Modal from "antd/lib/modal";
import "antd/dist/antd.css";

// utils
import defineElement from "src/utils/defineElement";

const BaseModal: React.FC<{
    title?: string;
    visible: boolean;
    onOk?: () => {};
    onCancel?: () => {};
    okText: string;
    cancelText: string;
    confirmLoading: boolean;
    footer: any;
    size: string;
    rest?: any;
    width: number;
    className?: string;
}> = ({ title, visible, children, onOk, onCancel, okText, cancelText, confirmLoading, footer, size, width, className, rest }) => {
    const renderFooter = useMemo(() => {
        if (!footer) return null;

        if (Array.isArray(footer)) return footer;

        return defineElement(footer);
    }, [footer]);

    const mergedProps = {
        ...rest,
        ...(footer ? { footer: renderFooter } : {}),
    };

    return (
        <React.Fragment>
            <Modal
                {...mergedProps}
                confirmLoading={confirmLoading}
                onCancel={onCancel}
                onOk={onOk}
                title={title}
                visible={visible}
                width={width}
                className={className}
            >
                {children}
            </Modal>
        </React.Fragment>
    );
};

export default BaseModal;
