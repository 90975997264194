import React from "react";
import cn from "classnames/bind";

// styles
import styles from "./ButtonWalletWrap.module.scss";

const cx = cn.bind(styles);

const ButtonWalletWrap: React.FC<{ children: any }> = ({ children }) => {
    return <div className={cx("button-wallet-wrap")}>{children}</div>;
};

export default ButtonWalletWrap;
