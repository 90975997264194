import React from "react";

const CloseIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7.95196 6.71632L13.7072 0.770264L15.3512 2.46879L9.59597 8.41485L15.3512 14.3609L13.7072 16.0594L7.95196 10.1134L2.19675 16.0594L0.552734 14.3609L6.30795 8.41485L0.552734 2.46879L2.19675 0.770264L7.95196 6.71632Z'
                fill='currentColor'
            />
        </svg>
    );
};

export default CloseIcon;
