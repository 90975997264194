import { LIST_ARTIST } from "src/consts/verifiedArtist";
import _ from 'lodash';

export const handleExecute = async (contract, sender, msg, { gas, fees, funds, memo, mode = "BROADCAST_MODE_BLOCK", type }) => {
    const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
    try {
        if (contract === marketplace1155) {
            const isApprovedResult = await window.Wasm.query(
                ow1155,
                JSON.stringify({
                    is_approved_for_all: {
                        owner: sender,
                        operator: marketplace1155,
                    },
                })
            );

            if (!isApprovedResult?.data?.approved) {
                const approveResult = await handleExecute(
                    ow1155,
                    sender,
                    JSON.stringify({
                        approve_all: { operator: marketplace1155 },
                    }),
                    {
                        gas: parseInt(gas),
                        fees: parseInt(fees),
                        mode: "BROADCAST_MODE_BLOCK",
                    }
                );
            }
        } else if (contract === marketplace) {
            const isApprovedResult = await window.Wasm.query(
                ow721,
                JSON.stringify({
                    approved_for_all: {
                        owner: sender,
                    },
                })
            );

            let isApproved = false;
            isApprovedResult?.data?.operators?.forEach((item) => {
                if (item?.spender === marketplace) {
                    isApproved = true;
                    return;
                }
            });

            if (!isApproved) {
                const approveResult = await handleExecute(
                    ow721,
                    sender,
                    JSON.stringify({
                        approve_all: { operator: marketplace },
                    }),
                    {
                        gas: parseInt(gas),
                        fees: parseInt(fees),
                        mode: "BROADCAST_MODE_BLOCK",
                    }
                );
            }
        }
    } catch (error) {
        throw new Error(`Cannot approve permission in check wallet with error: ${error.message || error.toString()}`);
    }

    const isOraiWallet = localStorage.getItem("isOraiWallet");
    console.log("is orai wallet: ", isOraiWallet);
    // sign transactions using Oraichain wallet
    // if (isOraiWallet === "true") {
    const sentFunds = _.isNil(type) ? funds : type === "native" ? [{ denom: "orai", amount: funds }] : null;
    const result = await window.Wasm.execute(contract, msg, sentFunds);
    return result;
};

//check verified artist
export const IsVerifiedArtist = (address) => {
    return LIST_ARTIST?.includes(address);
};
