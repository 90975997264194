import React from "react";

const LikeNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M5.23666 3.881L5.23667 3.88099C5.01779 3.65782 4.75675 3.48039 4.4687 3.35899C4.18066 3.23759 3.87135 3.17465 3.55877 3.17384C3.24619 3.17302 2.93656 3.23434 2.64788 3.35423C2.35929 3.47409 2.09738 3.6501 1.87738 3.87204C0.950326 4.80843 0.952118 6.33597 1.88801 7.28125L1.5327 7.63303L1.88801 7.28125L6.01696 11.4516L10.1228 7.30704C10.1231 7.30671 10.1234 7.30639 10.1237 7.30606C10.5696 6.85045 10.8185 6.23781 10.8168 5.60029C10.815 4.96231 10.5624 4.35064 10.1134 3.89738L10.1115 3.89546C9.89237 3.67182 9.63095 3.49401 9.34245 3.37238C9.05395 3.25074 8.74414 3.1877 8.43105 3.18694C8.11796 3.18617 7.80784 3.24769 7.51875 3.36792C7.22975 3.48811 6.96753 3.66456 6.74735 3.88703L5.23666 3.881ZM5.23666 3.881L5.23863 3.88299L5.64067 4.28835C5.73466 4.38311 5.86264 4.43637 5.99611 4.43625C6.12958 4.43614 6.25746 4.38266 6.35129 4.28774L6.74715 3.88723L5.23666 3.881ZM5.97436 11.4946C5.97448 11.4945 5.9746 11.4944 5.97473 11.4943L5.97436 11.4946Z'
                stroke='currentColor'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default LikeNotificationIcon;
