import { toast } from 'react-toastify';

//Check Approved
export const checkApproved = async (sender) => {
    const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
    const result = {
        isApproved721: false,
        isApproved1155: false,
    };

    //1155
    const isApprovedResult1155 = await window.Wasm.query(
        ow1155,
        JSON.stringify({
            is_approved_for_all: {
                owner: sender,
                operator: marketplace1155,
            },
        })
    );
    result.isApproved1155 = isApprovedResult1155?.data?.approved ? true : false;

    //721
    const isApprovedResult721 = await window.Wasm.query(
        ow721,
        JSON.stringify({
            approved_for_all: {
                owner: sender,
            },
        })
    );
    isApprovedResult721?.data?.operators?.forEach((item) => {
        if (item?.spender === marketplace) {
            result.isApproved721 = true;
            return;
        }
    });

    return result;
};

//Is Approved
export const isApproved = async (address, contractType) => {
    const result = await checkApproved(address);
    switch (contractType) {
        case "1155":
            return result?.isApproved1155;
        case "721":
            return result?.isApproved721;
        case "all":
            return !result?.isApproved721 || !result?.isApproved1155 ? false : true;
        default:
            return false;
    }
};

//Approve Account
export const approveAccount = async (sender) => {
    let resultApprove = true;
    try {
        const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
        const result = await checkApproved(sender);

        if (!result?.isApproved721 || !result?.isApproved1155) {
            let inputRaws = [];
            inputRaws.push(
                {
                    contractAddress: ow721,
                    msg:
                    {
                        approve_all: { operator: marketplace },
                    },
                },
                {
                    contractAddress: ow1155,
                    msg:
                    {
                        approve_all: { operator: marketplace1155 },
                    },
                }
            );

            await window.Wasm.executeMultipleTransaction(inputRaws);
        }

        // if (!result?.isApproved721) {
        //     const approveResult = await handleExecute(
        //         ow721,
        //         sender,
        //         JSON.stringify({
        //             approve_all: { operator: marketplace },
        //         }),
        //         { gas: 0, fees: 0, mode: "BROADCAST_MODE_BLOCK" }
        //     );
        //     console.log(approveResult, "APProve RESULT");

        //     if (approveResult?.tx_response.code !== 0) {
        //         resultApprove = false;
        //         throw new Error("Cannot approve permission");
        //     }
        // }
        // if (!result?.isApproved1155) {
        //     const approveResult = await handleExecute(
        //         ow1155,
        //         sender,
        //         JSON.stringify({
        //             approve_all: { operator: marketplace1155 },
        //         }),
        //         { gas: 0, fees: 0, mode: "BROADCAST_MODE_BLOCK" }
        //     );

        //     if (approveResult?.tx_response.code !== 0) {
        //         resultApprove = false;
        //         throw new Error("Cannot approve permission");
        //     }
        // }
        return resultApprove;
    } catch (error) {
        console.log(error, "ERRORRRR!!!!");
        resultApprove = false;
        toast.warn(error.message);
        throw new Error("Cannot approve permission");
    }
};

//revert approve
export const revertApprove = async (sender) => {
    const { marketplace1155, ow1155, marketplace, ow721 } = window.Wasm.contractAddresses;
    let resultRevertApprove = true;
    const result = await checkApproved(sender);

    try {
        if (result?.isApproved721) {
            await window.Wasm.execute(
                ow721,
                JSON.stringify({
                    revoke_all: { operator: marketplace },
                }),
                sender,
                { gas: 0, fees: 0, mode: "BROADCAST_MODE_BLOCK" }
            );
        }

        if (result?.isApproved1155) {
            await window.Wasm.execute(
                ow1155,
                JSON.stringify({
                    revoke_all: { operator: marketplace1155 },
                }),
                sender,
                { gas: 0, fees: 0, mode: "BROADCAST_MODE_BLOCK" }
            );
        }
    } catch (error) {
        throw new Error(`Cannot approve permission in approve account with error: ${error.message || error.toString()}`);
    }

    return resultRevertApprove;
};
