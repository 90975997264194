import React from 'react';
import cn from 'classnames/bind';
import { decimalFormatter, defaultOptions } from 'src/utils/currency';
import { formatCurrency } from 'src/utils/currency';

// style
import styles from './PriceBNBDisplay.module.scss';

const cx = cn.bind(styles);

const currencyMap = {
    usd: '$'
};

const currency = 'usd';

const PriceBNBDisplay: React.FC<{ dataPriceBNB?: any; ethBalance?: number | undefined }> = ({ dataPriceBNB, ethBalance }) => {
    return (
        <div className={cx('price-bnb-display')}>
            <div className={cx('price-bnb-display-currency')}>
                {currencyMap[currency]}&nbsp;
                {ethBalance && Number(ethBalance) > 0
                    ? formatCurrency(
                          (Number(ethBalance) * 10 ** 6 * dataPriceBNB?.[currency]) / Math.pow(10, defaultOptions.significantDigits)
                      )
                    : '0'}
            </div>
            <div className={cx('price-bnb-display-bnb')}>{ethBalance}&nbsp;BNB</div>
        </div>
    );
};

export default PriceBNBDisplay;
