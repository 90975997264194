import React from "react";

const YoutubeIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00586 9.49878L9.60815 8L7.00586 6.50122V9.49878Z" fill="#D7D7D7" />
            <path d="M8.00195 0C3.58435 0 0.00195312 3.5824 0.00195312 8C0.00195312 12.4176 3.58435 16 8.00195 16C12.4196 16 16.002 12.4176 16.002 8C16.002 3.5824 12.4196 0 8.00195 0ZM13.0007 8.00818C13.0007 8.00818 13.0007 9.63062 12.7949 10.413C12.6796 10.8412 12.3419 11.1788 11.9137 11.2941C11.1313 11.5 8.00195 11.5 8.00195 11.5C8.00195 11.5 4.88074 11.5 4.09021 11.2859C3.66199 11.1707 3.32434 10.8329 3.20898 10.4047C3.00305 9.63062 3.00305 8 3.00305 8C3.00305 8 3.00305 6.37769 3.20898 5.59534C3.32422 5.16711 3.67017 4.82117 4.09021 4.70593C4.87256 4.5 8.00195 4.5 8.00195 4.5C8.00195 4.5 11.1313 4.5 11.9137 4.71411C12.3419 4.82935 12.6796 5.16711 12.7949 5.59534C13.009 6.37769 13.0007 8.00818 13.0007 8.00818Z" fill="#D7D7D7" />
        </svg>
    );
};

export default YoutubeIcon;
