import React from "react";

const CommentNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M3.6202 10.1029C3.6202 9.82674 3.39634 9.60288 3.1202 9.60288H2.7602C2.16854 9.60288 1.7002 9.13118 1.7002 8.56328V4.53728C1.7002 3.96938 2.16854 3.49768 2.7602 3.49768H9.2402C9.83185 3.49768 10.3002 3.96938 10.3002 4.53728V8.56328C10.3002 9.13165 9.83198 9.60288 9.2402 9.60288H6.4862C6.37931 9.60288 6.27523 9.63713 6.18924 9.70062L3.78292 11.477C3.78287 11.477 3.78282 11.4771 3.78277 11.4771C3.76056 11.4934 3.73283 11.5004 3.70555 11.4965L3.63518 11.9915L3.70554 11.4965C3.67853 11.4927 3.65407 11.4785 3.6373 11.457C3.62626 11.4422 3.62026 11.4241 3.6202 11.4056V10.1029Z'
                stroke='currentColor'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default CommentNotificationIcon;
