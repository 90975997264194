//Component Item Price Recommend
import React from 'react';
import cn from 'classnames/bind';
//theme light dark
import { useSelector } from "react-redux";
import { selectThemeId } from "src/store/slices/activeThemeSlice";
import { themeIds } from "src/consts/theme";
//styles
import styles from './index.module.scss';
const cx = cn.bind(styles);

const ItemPriceRecommend: React.FC<{
  item: any,
  itemPriceReco: any,
  setItemPriceReco: Function,
  setValuePrice: Function,
}> = ({item, itemPriceReco, setItemPriceReco, setValuePrice}) => {
  //use Theme
  const activeThemeId = useSelector(selectThemeId);
  const isDarkTheme = activeThemeId === themeIds.DARK;

  return (
    <div 
      className={cx('p-item', item === itemPriceReco ? 'active' : '')}
      onClick={() => {setItemPriceReco(item); setValuePrice(item);}}
      style={{ background: isDarkTheme ? '#1e1e21' : '#E7EAF2' }}
    >
      {item} ORAI
    </div>
  );
}

export default ItemPriceRecommend;