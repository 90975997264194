// @ts-nocheck
import React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames/bind';
import { selectPrice } from 'src/store/slices/priceSlice';
import { currencyFormatter, decimalFormatter, defaultOptions } from 'src/utils/currency';
import { formatCurrency } from 'src/utils/currency';
import styles from './OraiToUsdDisplay.module.scss';

const cx = cn.bind(styles);

const currencyMap = {
    usd: '$'
};

const PriceToUsdDisplay: React.FC<{
    amount: number | undefined;
    currency?: string;
    className?: string;
    denom?: string;
}> = ({ amount, currency = 'usd', className, denom = 'AIRI' }) => {
    const price = useSelector(selectPrice);

    return (
        <>
            {price?.[denom?.toLowerCase()]?.[currency] && (
                <span className={cx('price', className)}>
                    {currencyMap[currency]}
                    {amount
                        ? formatCurrency(
                              (amount * price?.[denom?.toLowerCase()]?.[currency]) / Math.pow(10, defaultOptions.significantDigits)
                          )
                        : '0'}
                </span>
            )}
        </>
    );
};

export default PriceToUsdDisplay;
