import { useState, useEffect } from "react";
import callApi from 'src/api/callApi';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useHistory } from "react-router-dom";

interface RequestReponse {
  loading: boolean,
  error: any,
  data: any,
}

type Options = {
  showError?: boolean,
  resolve?: (data?: any) => void,
  reject?: (error?: any) => void,
}

function useFetch(reqParam: any, dependencies: any[], options: Options = { showError: true }): RequestReponse {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    const source = axios.CancelToken.source(); 

    async function doRequest() {
      try {
        setError(null);
        const res = await callApi({
          ...reqParam,
          cancelToken: source.token,
        });
        setData(res.data);
        setLoading(false);

        options.resolve && options.resolve(res.data);
      } catch (error) {

          if (axios.isCancel(error)) return;
          setLoading(false);
          setError(error.response);

          if (options.showError) {
            toast.error("An error occurred, please try again later.");
          } else {
            history.push("/not-found");
          }
      }
    }

    doRequest();
    
    return () => {
      source.cancel("Component got unmounted");
    };
  }, dependencies);

  return { loading, error, data };
}

export default useFetch;