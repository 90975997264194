import React from "react";

const TelegramIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.00195 16C12.4213 16 16.002 12.4193 16.002 8C16.002 3.58067 12.4213 0 8.00195 0C3.58262 0 0.00195312 3.58067 0.00195312 8C0.00195312 12.4193 3.58262 16 8.00195 16ZM3.66262 7.82667L11.376 4.85267C11.734 4.72333 12.0466 4.94 11.9306 5.48133L11.9313 5.48067L10.618 11.668C10.5206 12.1067 10.26 12.2133 9.89529 12.0067L7.89529 10.5327L6.93062 11.462C6.82395 11.5687 6.73395 11.6587 6.52729 11.6587L6.66929 9.62333L10.376 6.27467C10.5373 6.13267 10.34 6.05267 10.1273 6.194L5.54662 9.078L3.57195 8.462C3.14329 8.326 3.13395 8.03333 3.66262 7.82667Z" fill="#D7D7D7" />
        </svg>
    );
};

export default TelegramIcon;
