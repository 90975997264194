import React, { useContext, useState } from 'react';
import cn from 'classnames/bind';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiFillCheckCircle } from 'react-icons/ai';
import ImageApprove from 'src/assets/images/market/approve.png';
import { approveAccount } from 'src/utils/approveAccount';
import { notification } from 'antd';
import { selectWallet } from 'src/store/slices/walletSlice';
import NftContext from 'src/components/pages/NftDetail/NftContext/NftContext';
import { useSelector } from 'react-redux';
//style
import styles from './index.module.scss';
import { toast } from 'react-toastify';
const cx = cn.bind(styles);

const MarketApproveDialog: React.FC<{
    setOpenApproveDialog: Function;
}> = ({ setOpenApproveDialog }) => {
    //context
    const { contextData, contextAction } = useContext(NftContext);
    const wallet = useSelector(selectWallet);
    const [loading, setLoading] = useState(false);

    //handle submit
    const handleSubmit = async () => {
        if (wallet?.publicAddress === '0x') return;
        if (!wallet?.isEmailVerified) {
            toast.warning('Please verify your wallet to approve the NFT Market contract!');
            return;
        }
        setLoading(true);
        const result = await approveAccount(wallet?.publicAddress);
        if (result) {
            // refetch after 4 seconds
            setTimeout(() => {
                setLoading(false);
                notification.success({ message: 'You have successfully approved new contract!' });
                setOpenApproveDialog(false);
                contextAction.setRefreshApproval(!contextData.refreshApproval);
            }, 4000);
        } else {
            setLoading(false);
            notification.error({ message: 'Approve contract failed! Something went wrongs' });
        }
    };

    return (
        <Modal visible={true} footer={false} width={630} onCancel={() => setOpenApproveDialog(false)}>
            <div className={cx('wrap-approve')}>
                <div className={cx('box-img')}>
                    <img src={ImageApprove} alt='' />
                </div>
                <div className={cx('text-head')}>
                    <RiErrorWarningFill /> Please attention!
                </div>
                <div className={cx('title')}>Approve the new market contract</div>
                <div className={cx('box-text')}>
                    We have updated the <span>NFT marketplace contract</span> to the <span>new version</span>.<br />
                    Please sign the approval transaction below to give our contract the permission to process all the functionalities
                    on your NFT in the marketplace.
                    <p>
                        <RiErrorWarningFill /> If you don’t approve, other users can not buy or place a bid on your NFTs!
                    </p>
                </div>
                <Button loading={loading} onClick={handleSubmit}>
                    <AiFillCheckCircle />
                    Approve now
                </Button>
            </div>
        </Modal>
    );
};

export default MarketApproveDialog;
