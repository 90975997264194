import React from "react";
import cn from "classnames/bind";
import WarningIcon from "src/components/icons/WarningIcon";
import styles from "./Error.module.scss";
const cx = cn.bind(styles);

const Error: React.FC<{ classNameCustom?: string; style?: any; custom?: boolean }> = ({
    style,
    children,
    classNameCustom = "",
    custom = false,
}) => {
    return (
        <div style={style} className={cx("error", custom && "error-custom")}>
            <WarningIcon className={cx("error-icon")} />
            <div className={`${cx("error-text")} ${classNameCustom}`}>({children})</div>
        </div>
    );
};

export default Error;
