import React from "react";
import PropTypes from "prop-types";

const MoonIcon: React.FC<{ className: string }> = ({ className }) => {
	return (
		<svg className={className} viewBox='0 0 24 24'>
			<path
				d='M11.1 12.08c-2.33-4.51-.5-8.48.53-10.07C6.27 2.2 1.98 6.59 1.98 12c0 .14.02.28.02.42.62-.27 1.29-.42 2-.42 1.66 0 3.18.83 4.1 2.15 1.67.48 2.9 2.02 2.9 3.85 0 1.52-.87 2.83-2.12 3.51.98.32 2.03.5 3.11.5 3.5 0 6.58-1.8 8.37-4.52-2.36.23-6.98-.97-9.26-5.41z'
				fill='currentColor'></path>
			<path d='M7 16h-.18C6.4 14.84 5.3 14 4 14c-1.66 0-3 1.34-3 3s1.34 3 3 3h3c1.1 0 2-.9 2-2s-.9-2-2-2z' fill='currentColor'></path>
		</svg>
	);
};

export default MoonIcon;
