import React from "react";

const GithubIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0.333252C3.58 0.333252 0 3.85325 0 8.19459C0 11.6686 2.292 14.6146 5.47 15.6533C5.87 15.7273 6.01667 15.4839 6.01667 15.2753C6.01667 15.0886 6.01 14.5939 6.00667 13.9386C3.78133 14.4126 3.312 12.8839 3.312 12.8839C2.948 11.9766 2.422 11.7339 2.422 11.7339C1.69733 11.2466 2.478 11.2566 2.478 11.2566C3.28133 11.3113 3.70333 12.0666 3.70333 12.0666C4.41667 13.2686 5.576 12.9213 6.03333 12.7206C6.10533 12.2119 6.31133 11.8659 6.54 11.6693C4.76333 11.4726 2.896 10.7966 2.896 7.78459C2.896 6.92659 3.206 6.22525 3.71933 5.67525C3.62933 5.47658 3.35933 4.67725 3.78933 3.59459C3.78933 3.59459 4.45933 3.38392 5.98933 4.40059C6.62933 4.22592 7.30933 4.13925 7.98933 4.13525C8.66933 4.13925 9.34933 4.22592 9.98933 4.40059C11.5093 3.38392 12.1793 3.59459 12.1793 3.59459C12.6093 4.67725 12.3393 5.47658 12.2593 5.67525C12.7693 6.22525 13.0793 6.92659 13.0793 7.78459C13.0793 10.8046 11.2093 11.4693 9.42933 11.6626C9.70933 11.8986 9.96933 12.3806 9.96933 13.1173C9.96933 14.1693 9.95933 15.0146 9.95933 15.2699C9.95933 15.4759 10.0993 15.7219 10.5093 15.6433C13.71 14.6113 16 11.6633 16 8.19459C16 3.85325 12.418 0.333252 8 0.333252Z" fill="#D7D7D7" />
        </svg>
    );
};

export default GithubIcon;
