// @ts-nocheck
import React, { useEffect, useRef, useState, useContext, useCallback, useMemo } from "react";
import _ from "lodash";
import cn from "classnames/bind";
import copy from "copy-to-clipboard";
import styles from "./MobileDropdownProfile.module.scss";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { IWallet } from "src/types/redux";
import { useLocation } from "react-router-dom";
import CenterEllipsis from "../CenterEllipsis/CenterEllipsis";
import { RiErrorWarningFill } from "react-icons/ri";
import { AiFillCheckCircle } from "react-icons/ai";
import { setWalletInformation, selectWallet } from "src/store/slices/walletSlice";
import { showAlertBox } from "src/store/slices/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormatter from "../CurrencyFormatter/CurrencyFormatter";
import AvatarPlaceholder from "../AvatarPlaceholder/AvatarPlaceholder";
import { getEthBalance } from "src/utils/web3Service";
import { useCheckMobileScreen, useConnectKeplrWallet } from "src/utils/hooks";
import useToken from "src/utils/token";
import { connectorsByName, ConnectorNames } from "src/consts/connectors";
import { useWeb3React } from "@web3-react/core";
import { isApproved } from "src/utils/approveAccount";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// icons
import IconCheckAccount from "src/assets/icons/CheckAccount.svg";
import MetaMaskIcon from "src/assets/icons/fox.svg";
import WalletConnectIcon from "src/assets/icons/wallet-connect.svg";
import CopyIcon from "src/assets/icons/CopyIcon";
import LogoutIcon from "src/assets/icons/LogoutIcon";
import IconTopup from "src/assets/icons/IconTopup";
import DisconnectDiscordImage from "src/assets/images/disconnect-connect-discord.svg";
import IconDiscord from "src/assets/icons/Icon-discord-white.svg";
import AIRightIcon from "src/assets/images/aiRight-logo-img.svg";
import Oraichain_Icon_Image_Light from "src/assets/images/Oraichain_Icon_Image_Light.svg";
import Oraichain_Icon_Image_Dark from "src/assets/images/Oraichain_Icon_Image_Dark.svg";
import active_wallet_dark from "src/assets/icons/Wallet/active_wallet_dark.svg";
import active_wallet_light from "src/assets/icons/Wallet/active_wallet_light.svg";
import approve_contract_dark from "src/assets/icons/Wallet/approve_contract_dark.svg";
import approve_contract_light from "src/assets/icons/Wallet/approve_contract_light.svg";
import copy_wallet_dark from "src/assets/icons/Wallet/copy_wallet_dark.svg";
import copy_wallet_light from "src/assets/icons/Wallet/copy_wallet_light.svg";
import sign_out_dark from "src/assets/icons/Wallet/sign_out_dark.svg";
import sign_out_light from "src/assets/icons/Wallet/sign_out_light.svg";
import bnb_dark from "src/assets/icons/Wallet/bnb-dark.svg";
import bnb_light from "src/assets/icons/Wallet/bnb-light.svg";
import DropdownIcon from "src/assets/icons/Wallet/Dropdown.svg";
import OwalletIcon from "src/assets/icons/Wallet/owallet.svg";
import DropdownDarkIcon from "src/assets/icons/Wallet/Dropdown-dark.svg";

// components
import PriceDisplay from "../PriceDisplay/PriceDisplay";
import TopUpModal from "../TopUpModal";
import Payment from "src/components/pages/NftDetail/Payment";
import Modal from "src/components/common/Modal";
import ButtonAntd from "antd/lib/button";
import ButtonWalletWrap from "./ButtonWalletWrap";
import PriceBNBDisplay from "./PriceBNBDisplay";
import PriceAIRIDisplay from "./PriceAIRIDisplay";

// utils
import { IsVerifiedArtist } from 'src/utils/checkWallet';
import ThemSetup from 'src/utils/themeSetup';
import configEnv from '../../../utils/config.env';

// context
import NftContext from "src/components/pages/NftDetail/NftContext/NftContext";

// api
import { callApi } from "src/api";
import MetaMaskOnboarding from "@metamask/onboarding";
import { notification } from "antd";
import axios from "axios";
import { useMutate } from "restful-react";

const cx = cn.bind(styles);

const MobileDropdownProfile: React.FC<{
    walletAddress: string;
    erc20Address: string;
    oraiAddress: string;
    isErc20: bool;
    onConnectWallet?: Function;
    setMobileWalletInforDropdown: Function;
    deactivate: Function;
    setOpenApproveDialog: Function;
    setOpenVerifyDialog: Function;
    dataPriceBNB: any;
}> = ({
    walletAddress,
    erc20Address,
    oraiAddress,
    isErc20,
    onConnectWallet,
    setMobileWalletInforDropdown,
    deactivate,
    setOpenApproveDialog,
    setOpenVerifyDialog,
    dataPriceBNB,
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [ethBalance, setEthBalance] = useState(0);
    const walletInformation = (info) => dispatch(setWalletInformation(info));
    const history = useHistory();
    const wallet = useSelector<IWallet>(selectWallet);
    const [openHoverSection, setOpenHoverSection] = useState(false);
    const { balance, publicAddress: address, airiBalance } = wallet;
    const isOraiConnected = walletAddress && walletAddress !== "0x";
    const iframeRef = useRef();
    const { deleteToken, saveToken } = useToken();
    const { connectKeplrWallet } = useConnectKeplrWallet();
    const { isDarkTheme } = ThemSetup();
    const [isKeplr, setIsKeplr] = useState(false);
    //nft context
    const { contextData, contextAction } = useContext(NftContext);
    const [isApproveContract, setIsApproveContract] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loadingDisconnect, setLoadingDisconnect] = useState<boolean>(false);
    const [activeMoreOptions, setActiveMoreOptions] = useState(false);
    const [activeMoreOptionsBSCWallet, setActiveMoreOptionsBSCWallet] = useState(false);
    const alertBox = (payload) => dispatch(showAlertBox(payload));

    // more options
    const MORE_OPTIONS = [
        {
            key: "copy_wallet",
            value: "Copy wallet address",
            icon: isDarkTheme ? copy_wallet_dark : copy_wallet_light,
            onClick: () => {
                copy(oraiAddress);
                notification.success({ message: "Copied!" });
            },
            isDisable: false,
        },
        {
            key: "active_wallet",
            value: "Activate your wallet",
            icon: isDarkTheme ? active_wallet_dark : active_wallet_light,
            onClick: () => {
                setOpenVerifyDialog(true);
            },
            isDisable: wallet?.isEmailVerified,
            extraIcon: !wallet?.isEmailVerified && (
                <RiErrorWarningFill className={cx("warning-icon")} style={{ color: isDarkTheme ? "#ffc700" : "#e9ad00" }} />
            ),
        },
        {
            key: "approve",
            value: "Approve the NFT Market contract",
            icon: isDarkTheme ? approve_contract_dark : approve_contract_light,
            onClick: () => {
                setOpenApproveDialog(true);
            },
            isDisable: isApproveContract,
            extraIcon: !isApproveContract && <RiErrorWarningFill className={cx("warning-icon")} style={{ color: "#FF3A3A" }} />,
        },
        {
            key: "sign_out",
            value: "Sign out",
            icon: isDarkTheme ? sign_out_dark : sign_out_light,
            onClick: () => logoutOraiWallet(),
            isDisable: false,
        },
    ];

    const MORE_OPTIONS_BSC_WALLET = [
        {
            key: "copy_wallet",
            value: "Copy wallet address",
            icon: isDarkTheme ? copy_wallet_dark : copy_wallet_light,
            onClick: () => {
                copy(erc20Address);
                notification.success({ message: "Copied!" });
            },
        },
        {
            key: "sign_out",
            value: "Sign out",
            icon: isDarkTheme ? sign_out_dark : sign_out_light,
            onClick: () => handleDisconnectMetamask(),
        },
    ];

    useEffect(() => {
        const checkKeplr = async () => {
            if (await window.Keplr.getKeplr()) setIsKeplr(true);
        };
        checkKeplr();
    }, []);

    if (erc20Address && erc20Address !== "0x") {
        getEthBalance(erc20Address).then((res) => {
            setEthBalance((res / 10 ** 18).toFixed(2));
        });
    }

    const logoutOraiWallet = () => {
        iframeRef.current?.forceLogout();

        setTimeout(() => {
            // clear redux values
            setMobileWalletInforDropdown(false);
            deleteToken();
            walletInformation({
                publicAddress: "0x",
                picture: "",
                username: "",
                childKey: "",
            });
            localStorage.removeItem('isOraiWallet');
            // check if the use is under the /profile router => push to home
            if (location.pathname.match(/\/profile/gi)) {
                history.push("/");
            }
        }, 500);
    };

    const onOpenProfile = () => {
        setMobileWalletInforDropdown(false);
        onConnectWallet();
        history.push(`/profile/${wallet?.publicAddress}`);
    };

    const context = useWeb3React();
    const { connector, activate, error } = context;

    const onboarding = React.useRef();

    React.useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    useEffect(() => {
        if (error) {
            if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector;
                walletConnector.walletConnectProvider = null;
            }
        }
    }, [error]);

    const connectErc20Wallet = async (name: any) => {
        if (ConnectorNames.WalletConnect === name) {
            return activate(connectorsByName[name]);
        }

        activate(connectorsByName[name]);

        const dappUrl = "https://staging.airight.io/";
        const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;

        return window.open(metamaskAppDeepLink);
    };

    const handleTopUp = () => {
        contextAction.setVisibleSelectTopUp(true);
    };

    const isApprovedFunc = async () => {
        const result = await isApproved(wallet?.publicAddress, "all");
        setIsApproveContract(result);
    };

    useEffect(() => {
        if (wallet?.publicAddress && wallet?.publicAddress !== "0x") {
            isApprovedFunc();
        }
    }, [wallet?.publicAddress, contextData.refreshApproval]);

    const DisconnectDiscord = async () => {
        let urlConfirmDiscord = "/providers/disconnect-discord";
        setLoadingDisconnect(true);

        try {
            const result: any = await callApi({
                method: "post",
                url: urlConfirmDiscord,
                contentType: "application/json",
            });

            walletInformation({
                ...wallet,
                isDiscordVerified: false,
            });

            history.push(`/profile/${wallet?.publicAddress}`);
            setLoadingDisconnect(false);
        } catch (error) {
            setLoadingDisconnect(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        DisconnectDiscord();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleConnectDiscord = useCallback(() => {
        if (wallet?.isDiscordVerified) {
            return showModal();
        }

        return (window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${configEnv.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${configEnv.REACT_APP_REDIRECT_URI_DISCORD}&response_type=token&scope=identify`);
    }, [window, wallet, showModal]);

    const renderModalDisconnectDiscord = useMemo(() => {
        const renderContentModal = () => {
            return (
                <>
                    <img className={cx("disconnect-connect-discord")} src={DisconnectDiscordImage} alt='disconnect-connect-discord' />
                    <div className={cx("text")}>Are you sure?</div>
                    <div className={cx("sub-text")}>
                        If you disconnect, your current memberships in the <br />
                        aiRight verified channels will expire, you will be <br />
                        removed from them!
                    </div>
                </>
            );
        };

        const renderFooter = () => {
            return (
                <div style={{ display: "flex" }}>
                    <ButtonAntd
                        onClick={handleCancel}
                        style={{
                            background: "#E7EAF2",
                            color: "#505665",
                        }}
                    >
                        Cancel
                    </ButtonAntd>
                    <ButtonAntd
                        loading={loadingDisconnect}
                        onClick={handleOk}
                        style={{
                            background: "#E7EAF2",
                            color: "#FF3A3A",
                        }}
                    >
                        Yes, disconnect
                    </ButtonAntd>
                </div>
            );
        };

        return (
            <Modal
                footer={renderFooter()}
                onCancel={handleCancel}
                size='xs'
                visible={isModalVisible}
                width={400}
                okText={"Ok"}
                cancelText={"Cancel"}
                confirmLoading={true}
            >
                {renderContentModal()}
            </Modal>
        );
    }, [isModalVisible, loadingDisconnect]);

    const toggleMoreOptionsMenu = () => {
        return setActiveMoreOptions((prevState) => !prevState);
    };

    const toggleMoreOptionsBSCWallet = () => {
        return setActiveMoreOptionsBSCWallet((prevState) => !prevState);
    };

    //connect metamask

    const [userAddress, setUserAddress] = useState("");

    const handleDisconnectMetamask = () => {
        return setUserAddress("");
    };

    const handleConnectMetamask = (event) => {
        event.stopPropagation();

        return connect(setUserAddress);
    };

    async function connect(onConnected) {
        if (!window.ethereum) {
            return (window.location.href = metamaskAppDeepLink);
        }

        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        });

        onConnected(accounts[0]);
    }

    const metamaskAppDeepLink = `https://metamask.app.link/dapp/${configEnv.REACT_APP_SSO_SERVER}`;

    return (
        <div className={cx("profile-dropdown-content")}>
            <div
                className={cx("header")}
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div className={cx("header-title")}>My account</div>
                <div
                    style={{ cursor: "pointer" }}
                    className={cx("header-title")}
                    onClick={() => {
                        setMobileWalletInforDropdown(false);
                    }}
                >
                    Close
                </div>
            </div>

            <div className={cx("body")}>
                {oraiAddress && oraiAddress !== "0x" ? (
                    <div style={{ border: "none" }} className={cx("user")}>
                        <div className={cx("wrapper-wallet")}>
                            <ButtonWalletWrap>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                    onClick={() => {
                                        return toggleMoreOptionsMenu();
                                    }}
                                >
                                    <div className={cx("user-infor")}>
                                        <AvatarPlaceholder
                                            src={wallet?.picture}
                                            address={wallet?.publicAddress}
                                            className={cx("avatar")}
                                            onClick={onOpenProfile}
                                        />
                                        <div className={cx("balance")}>
                                            <div className={cx("balance-title")}>
                                                <span>{wallet?.username}</span>
                                                {IsVerifiedArtist(wallet?.publicAddress) && <img src={IconCheckAccount} alt='' />}
                                            </div>
                                            <div className={cx("balance-address")}>
                                                <CenterEllipsis
                                                    size={6}
                                                    className={cx("contact-address-value")}
                                                    text={oraiAddress ?? "0x"}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {!wallet?.isEmailVerified || !isApproveContract ? (
                                        <div className={cx("")}>
                                            <RiErrorWarningFill
                                                className={cx("warning-icon")}
                                                style={{
                                                    color: !isApproveContract ? "#FF3A3A" : "#FFC700",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <img src={isDarkTheme ? DropdownDarkIcon : DropdownIcon} />
                                    )}
                                </div>
                            </ButtonWalletWrap>

                            {activeMoreOptions ? (
                                <div className={cx("menu-more-options")}>
                                    {MORE_OPTIONS.map((item) => {
                                        return (
                                            <div
                                                className={cx("menu-more-options-item", item.isDisable && "disable")}
                                                onClick={() => {
                                                    if (item.isDisable) return;

                                                    return item.onClick();
                                                }}
                                            >
                                                <div className={cx("left-wrap")}>
                                                    <img className={cx("icon")} src={item.icon} />
                                                    <div className={cx("value")}>{item.value}</div>
                                                </div>
                                                {item?.extraIcon}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className={cx("menu-wrapper")}>
                                    <div className={cx("price-wrapper")}>
                                        <div className={cx("price-wrapper-airight")}>
                                            <div className={cx("left")}>
                                                <img src={AIRightIcon} className={cx("icon")} />
                                                <PriceDisplay
                                                    className={cx("price-value-text")}
                                                    isHaveBorder={false}
                                                    amount={airiBalance}
                                                    isTransaction={true}
                                                    denom={"AIRI"}
                                                    reverse={true}
                                                    displayIcon={false}
                                                />
                                            </div>
                                            {/* <div className={cx("top-up")} onClick={handleTopUp}>
                                                Top up
                                            </div> */}
                                        </div>

                                        <div className={cx("price-wrapper-orai")}>
                                            <div className={cx("left")}>
                                                <img
                                                    src={isDarkTheme ? Oraichain_Icon_Image_Dark : Oraichain_Icon_Image_Light}
                                                    className={cx("icon")}
                                                />
                                                <PriceDisplay
                                                    className={cx("price-value-text")}
                                                    isHaveBorder={false}
                                                    amount={balance}
                                                    isTransaction={true}
                                                    denom={"ORAI"}
                                                    reverse={true}
                                                    displayIcon={false}
                                                />
                                            </div>
                                            <div className={cx("top-up")} onClick={handleTopUp}>
                                                Top up
                                            </div>
                                        </div>
                                    </div>

                                    <div className={cx("button-connect-to-discord")} onClick={handleConnectDiscord}>
                                        <img className={cx("item-icon")} src={IconDiscord} alt='' />
                                        {wallet?.isDiscordVerified ? (
                                            <span>Disconnect {wallet?.tagName || wallet?.discord}</span>
                                        ) : (
                                            <span>Connect to Discord</span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {contextData.visibleSelectTopUp && <TopUpModal setVisibleTopUp={contextAction.setVisibleSelectTopUp} />}
                        <Payment />
                    </div>
                ) : null}

                {/* <div>
                    {oraiAddress && oraiAddress !== "0x" ? null : (
                        <div className={cx("user")} style={{ border: "none", paddingBottom: 0 }}>
                            <div className={cx("wallet-title-wrapper")}>
                                <div className={cx("wallet-name")}>Creating, selling and collecting artworks</div>
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            display: oraiAddress && oraiAddress !== "0x" ? "none" : "block",
                            paddingRight: 20,
                            paddingLeft: 20,
                        }}
                    >
                        <SSOWidget
                            componentRef={iframeRef}
                            type='inline'
                            text='Connect Oraichain Wallet'
                            buttonStyle={{
                                height: "54px",
                                display: "flex",
                                flexDirection: "row",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                color: "#f6f7fb",
                                borderRadius: "8px",
                                border: isDarkTheme ? "1px solid #505665" : "1px solid #BDC3D3",
                                background: isDarkTheme ? "#3C404D" : "#E7EAF2",
                                width: "100%",
                                padding: "0px",
                            }}
                        />
                    </div>
                </div> */}

                {oraiAddress && oraiAddress !== "0x" ? null : (
                    <div style={{ border: "none", padding: 0, marginTop: "16px" }} className={cx("user")}>
                        <div className={cx("connect-wallet", "connect-orai", "connect-wallet-keplr")} onClick={connectKeplrWallet}>
                            <img className={cx("connect-wallet-icon")} src={OwalletIcon} alt='' /> Connect OWallet
                        </div>
                    </div>
                )}

                {userAddress ? (
                    <div className={cx("bsc-wallet-wrapper")}>
                        <div className={cx("container")}>
                            <ButtonWalletWrap>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                    }}
                                    onClick={toggleMoreOptionsBSCWallet}
                                >
                                    <div className={cx("user-infor")}>
                                        <AvatarPlaceholder
                                            src={MetaMaskIcon}
                                            address={wallet?.publicAddress}
                                            className={cx("avatar-erc20")}
                                            onClick={() => {
                                                history.push(`/profile/${wallet?.publicAddress}?activeTab=bsc-list`);
                                            }}
                                        />
                                        <div className={cx("balance")}>
                                            <div className={cx("balance-title")}>BSC Wallet</div>
                                            <CenterEllipsis
                                                size={6}
                                                className={cx("contact-address-value")}
                                                text={userAddress ?? "0x"}
                                            />
                                        </div>
                                    </div>
                                    <img src={isDarkTheme ? DropdownDarkIcon : DropdownIcon} />
                                </div>
                            </ButtonWalletWrap>

                            {activeMoreOptionsBSCWallet ? (
                                <div className={cx("more-options-bsc-wallet")}>
                                    {MORE_OPTIONS_BSC_WALLET.map((item) => {
                                        return (
                                            <div className={cx("menu-more-options-item")} onClick={item.onClick}>
                                                <div className={cx("left-wrap")}>
                                                    <img className={cx("icon")} src={item.icon} />
                                                    <div className={cx("value")}>{item.value}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className={cx("balance-display")}>
                                    <div className={cx("left-wrap")}>
                                        <img src={isDarkTheme ? bnb_dark : bnb_light} />
                                        <div className={cx("balance")}>
                                            <PriceBNBDisplay dataPriceBNB={dataPriceBNB} ethBalance={ethBalance} />
                                        </div>
                                    </div>
                                    <div className={cx("top-up")} onClick={handleTopUp}>
                                        Top up
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className={cx("user")} style={{ paddingBottom: 0 }}>
                        <div className={cx("wallet-title-wrapper")}>
                            <div className={cx("wallet-name-bsc-wallet")}>BSC WALLET</div>
                            <div className={cx("wallet-sub-title")}>Only used for farming</div>
                        </div>
                        <div className={cx("connect-wallet", "connect-metamask")} onClick={(e) => handleConnectMetamask(e)}>
                            <img className={cx("connect-wallet-icon")} src={MetaMaskIcon} alt='' /> Connect Metamask
                        </div>
                        <div
                            className={cx("connect-wallet", "connect-wallet-connect")}
                            onClick={() => connectErc20Wallet(ConnectorNames.WalletConnect)}
                        >
                            <img className={cx("connect-wallet-icon")} src={WalletConnectIcon} alt='' /> Wallet Connect
                        </div>
                    </div>
                )}
            </div>
            {renderModalDisconnectDiscord}
        </div>
    );
};

export default MobileDropdownProfile;
