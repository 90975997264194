const retry = async (promise: Promise<any>, option: { retries: number, delay?: number }): Promise<any> => {
  const { retries, delay } = option;
  try {
    const data = await promise;

    if (data && data.data && data.data.status !== 200) {
      throw new Error(JSON.stringify(data.data.error));
    }
    
    return data;
  } catch (e) {

    if (retries <= 0 || retries === 1) {
      return Promise.reject(e);
    }

    if (delay) await waitFor(delay);

    console.log('retrying', retries, 'time');
    return retry(promise, { retries: retries - 1 });
  }
}

const waitFor = (millSeconds: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('success');
    }, millSeconds);
  });
}

export default retry;