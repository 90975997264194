import React from "react";

const RedditIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.67532 10.7296C9.31693 11.088 8.54898 11.216 8.00174 11.216C7.45291 11.216 6.68655 11.088 6.32816 10.7296C6.24332 10.6448 6.10733 10.6448 6.02261 10.7296C5.93777 10.8145 5.93777 10.9505 6.02261 11.0352C6.59061 11.6032 7.68179 11.648 8.00174 11.648C8.32169 11.648 9.41129 11.6032 9.98099 11.0352C10.0641 10.9503 10.0641 10.8144 9.98099 10.7296C9.89615 10.6448 9.76016 10.6448 9.67532 10.7296Z" fill="#D7D7D7" />
            <path d="M7.00122 8.83362C7.00122 8.37439 6.62683 8 6.1676 8C5.7085 8 5.33398 8.37439 5.33398 8.83362C5.33398 9.29272 5.7085 9.66724 6.1676 9.66724C6.62683 9.66724 7.00122 9.29285 7.00122 8.83362Z" fill="#D7D7D7" />
            <path d="M8.00195 0C3.58435 0 0.00195312 3.5824 0.00195312 8C0.00195312 12.4176 3.58435 16 8.00195 16C12.4196 16 16.002 12.4176 16.002 8C16.002 3.5824 12.4196 0 8.00195 0ZM12.6436 9.06714C12.6611 9.18237 12.6708 9.29919 12.6708 9.4176C12.6708 11.2128 10.5812 12.6672 8.00354 12.6672C5.4259 12.6672 3.3363 11.2128 3.3363 9.4176C3.3363 9.29761 3.34595 9.1792 3.36353 9.06396C2.95715 8.88159 2.67395 8.47363 2.67395 8C2.67395 7.35681 3.19556 6.83362 3.84033 6.83362C4.15393 6.83362 4.43713 6.95679 4.64673 7.15845C5.4563 6.57446 6.57642 6.20154 7.82275 6.16956C7.82275 6.15356 8.40515 3.37915 8.40515 3.37915C8.41638 3.32483 8.44836 3.27832 8.49475 3.24805C8.54114 3.21765 8.59717 3.20801 8.65161 3.21924L10.5908 3.63196C10.7268 3.35681 11.0067 3.16638 11.3347 3.16638C11.7955 3.16638 12.1683 3.53918 12.1683 4C12.1683 4.46082 11.7955 4.83362 11.3347 4.83362C10.8883 4.83362 10.5267 4.48157 10.506 4.04004L8.7699 3.67041L8.23877 6.17126C9.46277 6.21436 10.562 6.58557 11.3588 7.16162C11.5684 6.9585 11.8531 6.83362 12.1683 6.83362C12.8132 6.83362 13.3347 7.35522 13.3347 8C13.3347 8.47681 13.0483 8.88477 12.6436 9.06714Z" fill="#D7D7D7" />
            <path d="M9.83557 8C9.37634 8 9.00195 8.37439 9.00195 8.83362C9.00195 9.29272 9.37634 9.66724 9.83557 9.66724C10.2947 9.66724 10.6692 9.29272 10.6692 8.83362C10.6692 8.37439 10.2948 8 9.83557 8Z" fill="#D7D7D7" />
        </svg>
    );
};

export default RedditIcon;
