import axios, { AxiosRequestConfig, Method, AxiosPromise, CancelToken } from "axios";
import { getWithExpiry } from "src/lib/localStorage";
import configEnv from "../utils/config.env";

export type Request = {
    method: Method;
    url: string;
    data?: any;
    params?: any;
    token?: string;
    baseURL?: string;
    contentType?: string;
    cancelToken?: CancelToken;
};

export type Response = {
    data: any;
    error: any;
};

const callApi = <T>(reqParam: Request): AxiosPromise<T> => {
    const { method, url, data, params, baseURL, contentType, cancelToken } = reqParam;
    const config: AxiosRequestConfig = {
        method,
        url,
        data,
        params,
        cancelToken,
    };
    config.baseURL = baseURL || configEnv.REACT_APP_BASE_URL || "https://v1-api.airight.io";
    const token = getWithExpiry("token");

    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": contentType,
        };
    }

    const req = axios.request(config);
    return req;
};

export const headerAuth = () => {
    const token = getWithExpiry("token");
    if (!token) {
        return {
            headers: {}
        }
    }
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    };
}

export default callApi;
