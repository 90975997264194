import React from "react";

const TransferNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M8.64014 6.12283L10.5001 4.26282L8.64014 2.40283'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M1.5 4.26282H10.5' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
            <path
                d='M3.35999 7.67282L1.5 9.53284L3.35999 11.3928'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M10.5 9.53284H1.5' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
        </svg>
    );
};

export default TransferNotificationIcon;
