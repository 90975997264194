// @ts-nocheck
import "antd/dist/antd.css";
import cn from "classnames/bind";
import _, { isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";
import { callApi } from "src/api";
import ActiveNotificationIcon from "src/assets/icons/ActiveNotificationIcon";
import BurnNotificationIcon from "src/assets/icons/BurnNotificationIcon";
import BuyNotificationIcon from "src/assets/icons/BuyNotificationIcon";
import CollectionNotificationIcon from "src/assets/icons/CollectionNotificationIcon";
import CommentNotificationIcon from "src/assets/icons/CommentNotificationIcon";
import LikeNotificationIcon from "src/assets/icons/LikeNotificationIcon";
import MoreIcon from "src/assets/icons/MoreIcon";
import NotificationIcon from "src/assets/icons/NotificationIcon";
import OraiPriceNotificationIcon from "src/assets/icons/OraiPriceNotificationIcon";
import ReceiveRoyaltyNotificationIcon from "src/assets/icons/ReceiveRoyaltyNotificationIcon";
import TransferNotificationIcon from "src/assets/icons/TransferNotificationIcon";
import BadgeIconDisconnectDiscord from "src/assets/icons/badge-icon-disconnect-discord.svg";
import BadgeIconVerifyDiscord from "src/assets/icons/badge-icon-verify-discord.svg";
import DoubleCheckIcon from "src/assets/icons/double_check_icon.png";
import IconSuccessReport from "src/assets/icons/icon-success-report.svg";
import AiRightLogo from "src/assets/images/AIRI-logo.png";
import IconBell from "src/assets/images/icon-bell-light.svg";
import { themeIds } from "src/consts/theme";
import { selectThemeId } from "src/store/slices/activeThemeSlice";
import { selectWallet } from "src/store/slices/walletSlice";
import { getDurationAsTimeFromNow } from "src/utils/date";
import configEnv from "../../../utils/config.env";
import AvatarPlaceholder from "../AvatarPlaceholder/AvatarPlaceholder";
import PriceToUsdDisplay from "../PriceToUsdDisplay/PriceToUsdDisplay";
import styles from "./Notification.module.scss";

const cx = cn.bind(styles);

const scanURL = configEnv.REACT_APP_SCAN_URL || "https://testnet.scan.orai.io";

const NOTIFICATION_TYPE = {
  BUY_NFT: 1,
  FINISH_AUCTION_FOR_BIDER: 2,
  RECEIVE_ROYALTY: 3,
  ACTIVE_ACCOUNT: 4,
  MINT_NFT: 5,
  CREATE_COLLECTION: 6,
  BURN_NFT: 7,
  RECEIVE_NFT_FROM_TRANSFER: 8,
  TRANSFER_NFT: 9,
  POST_COMMENT: 10,
  POST_REACT: 11,
  TOPUP_SUCCESSED: 12,
  TOPUP_DISRUPTED: 13,
  TOPUP_RESENT: 14,
  TOPUP_FULLFILLED: 15,
  VERIFY_DISCORD: 16,
  DISCONNECT_DISCORD: 17,
  SUCCESS_REPORT: 18,
  FINISH_AUCTION_FOR_SELLER: 19,
  CLAIM_STAKING_REWARD: 20
};

const Notification: React.FC<NavLinksProps> = ({}) => {
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const sk = useRef(null);
  const wallet = useSelector(selectWallet);
  const [notifications, setNotifications] = useState([]);
  const [notificationPage, setNotificationPage] = useState(1);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationCountDisplay, setNotificationCountDisplay] = useState(true);
  const pageSize = 12;
  const [refetch, setRefetch] = useState(false);
  const notificationWrapperElementRef = useRef(null);
  const handleClickOutside = (event: any) => {
    if (dropdownRef && !dropdownRef?.current?.contains?.(event?.target)) {
      setShowDropdown(false);
    }
  };
  const activeThemeId = useSelector(selectThemeId);
  const isDarkTheme = activeThemeId === themeIds.DARK;

  useEffect(() => {
    return () => {
      if (sk?.current) {
        sk?.current?.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  const initNotifications = async () => {
    // Get notifications data
    if (notifications.length !== 0) {
      setNotifications([]);
      setNotificationPage(1);
    }
    const resultData = await callApi({
      method: "get",
      url: `notifications?size=${pageSize}&offset=${0}`,
      contentType: "application/json"
    });
    setNotifications(resultData.data);

    // Get new notifications count
    const resultCount = await callApi({
      method: "get",
      url: `notifications/count`,
      contentType: "application/json"
    });
    setNotificationCount(resultCount?.data?.count || 0);
  };

  useEffect(() => {
    if (!isNil(wallet?.publicAddress) && wallet?.publicAddress !== "0x") {
      initNotifications();
    }
  }, [wallet?.publicAddress]);

  const notificationsExtra = async () => {
    const res = await callApi({
      method: "get",
      url: `notifications?size=${pageSize}&offset=${pageSize * (notificationPage - 1)}`,
      contentType: "application/json"
    });
    setNotifications(_.concat(notifications, ...res.data));
  };

  useEffect(() => {
    if (notificationPage !== 1 && !isNil(wallet?.publicAddress) && wallet?.publicAddress !== "0x") {
      notificationsExtra();
    }
  }, [notificationPage, wallet?.publicAddress]);

  useEffect(() => {
    let socket: SocketIOClient.Socket;

    if (sk?.current) {
      socket = sk?.current;
    } else {
      socket = io(configEnv.REACT_APP_BASE_URL as string, {
        path: "/open-socket",
        transports: ["websocket"],
        rejectUnauthorized: false,
        agent: false
      });
    }

    socket.on("connect", () => {
      console.log("Connected");
      sk.current = socket;
    });

    socket.on("connect_error", (e: any) => {
      console.log(e);
    });

    socket.on(`push-notification-${wallet?.publicAddress}`, (message: any) => {
      onReceiveMessage(message);
    });

    return socket;
  }, []);

  const onReceiveNewNotification = (res) => {
    setNotifications((prevNotificationsData) => {
      return _.concat([res.data], prevNotificationsData);
    });
    setNotificationCount((prevNotificationCount) => {
      return prevNotificationCount + 1;
    });
    setNotificationCountDisplay(true);
  };

  const onReceiveMessage = async (message) => {
    const res = await callApi({
      method: "get",
      url: `notifications/${message?.notificationId}`,
      contentType: "application/json"
    });
    onReceiveNewNotification(res);
  };

  const resetNotificationCount = async () => {
    setNotificationCount(0);
    setNotificationCountDisplay(false);
    const result = await callApi({
      method: "patch",
      url: `notifications/reset-noti-count`,
      contentType: "application/json"
    });
  };

  const readSingleNotification = async (id, index) => {
    let notificationRow = notificationWrapperElementRef.current?.getElementsByClassName(
      cx("notification-row")
    )[index];
    notificationRow.classList.remove(cx("notification-row-unread"));
    await callApi({
      method: "patch",
      url: `notifications/read/${id}`,
      contentType: "application/json"
    });
  };

  const renderNotificationInfor = (data, index) => {
    let actionBadge;
    let actionText;
    let actionRow;
    let onClick;

    switch (data?.type) {
      case NOTIFICATION_TYPE.ACTIVE_ACCOUNT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-blue")}>
            <ActiveNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = "You have activated your account";
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.BUY_NFT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-green")}>
            <BuyNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            {<span className={cx("bold-text")}>{data?.sender?.username}</span>} bought{" "}
            <span className={cx("bold-text")}>
              {data?.transaction?.quantity || 1}{" "}
              {data?.transaction?.quantity === 1 ? "edition" : "editions"}
            </span>{" "}
            of the
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }{" "}
            at{" "}
            {
              <span className={cx("price-text")}>
                {data?.transaction?.price / 10 ** 6} {data?.transaction?.denom}
              </span>
            }{" "}
            (
            <PriceToUsdDisplay
              amount={data?.transaction?.price}
              className={cx("bold-text")}
              denom={data?.transaction?.denom}
            />
            )
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress}
                src={data?.sender?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          window.open(`${scanURL}/txs/${data?.transaction?.txHash}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.RECEIVE_ROYALTY:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-purple")}>
            <ReceiveRoyaltyNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have received{" "}
            {
              <span className={cx("price-text")}>
                {data?.data?.amount / 10 ** 6} {data?.transaction?.denom}
              </span>
            }{" "}
            (
            <PriceToUsdDisplay
              className={cx("bold-text")}
              amount={data?.data?.amount}
              denom={data?.transaction?.denom}
            />
            ) royalty from the
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                // address={data?.reicipient?.publicAddress}
                src={AiRightLogo}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.TRANSFER_NFT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-red")}>
            <TransferNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have transferred{" "}
            {
              <span className={cx("bold-text")}>
                {data?.transaction?.quantity || 1}{" "}
                {data?.transaction?.quantity === 1 ? "edition" : "editions"}
              </span>
            }{" "}
            of the
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }{" "}
            to{" "}
            {
              <span className={cx("bold-text")}>
                {data?.transaction?.receiver.slice(0, 5)}...
                {data?.transaction?.receiver.slice(-5)}
              </span>
            }
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                // address={data?.receipient?.publicAddress}
                src={AiRightLogo}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.RECEIVE_NFT_FROM_TRANSFER:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-green")}>
            <TransferNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have received{" "}
            {
              <span className={cx("bold-text")}>
                {data?.transaction?.quantity || 1}{" "}
                {data?.transaction?.quantity === 1 ? "edition" : "editions"}
              </span>
            }{" "}
            of the
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }{" "}
            from{" "}
            {
              <span className={cx("bold-text")}>
                {data?.transaction?.sender.slice(0, 5)}...
                {data?.transaction?.sender.slice(-5)}
              </span>
            }
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress}
                src={data?.sender?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          history.push(`/nft-detail/${data?.asset?.id}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };

        break;
      case NOTIFICATION_TYPE.BURN_NFT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-gray")}>
            <BurnNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have burned{" "}
            {
              <span className={cx("bold-text")}>
                {data?.transaction?.quantity || 1}{" "}
                {data?.transaction?.quantity === 1 ? "edition" : "editions"}
              </span>
            }{" "}
            of the
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.POST_REACT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-red")}>
            <LikeNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            <span className={cx("bold-text")}>{data?.sender?.username}</span> liked your post
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress}
                src={data?.sender?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          history.push(`/feed/${data?.post?.id}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.POST_COMMENT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-yellow")}>
            <CommentNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            <span className={cx("bold-text")}>{data?.sender?.username}</span> commented your post
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress}
                src={data?.sender?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          history.push(`/feed/${data?.post?.id}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.CREATE_COLLECTION:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-blue")}>
            <CollectionNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have created a collection{" "}
            <span className={cx("bold-text")}>{data?.collection?.name}</span>
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          history.push(`collection/${data?.collection?.id}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.FINISH_AUCTION_FOR_BIDER:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-orange")}>
            <OraiPriceNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have won
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }{" "}
            from the auction.
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress || data?.receipient?.publicAddress}
                src={data?.sender?.picture || data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          window.open(`${scanURL}/txs/${data?.transaction?.txHash}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.FINISH_AUCTION_FOR_SELLER:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-orange")}>
            <OraiPriceNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );
        actionText = (
          <>
            You have received{" "}
            <span className={cx("price-text")}>
              {data?.data?.amount / 10 ** 6} {data?.data?.denom || data?.transaction?.denom}
            </span>{" "}
            from the auction
            {
              <span className={cx("bold-text")}>
                {" "}
                {data?.asset?.name} #{data?.asset?.id}
              </span>
            }
          </>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.sender?.publicAddress || data?.receipient?.publicAddress}
                src={data?.sender?.picture || data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          window.open(`${scanURL}/txs/${data?.transaction?.txHash}`);
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      /*
            case NOTIFICATION_TYPE.TOPUP_SUCCESSED:
                actionBadge = (
                    <div className={cx('action-badge', 'action-badge-white')}>
                        <OraiPriceNotificationIcon className={cx('action-badge-icon')} />
                    </div>
                );
                actionText = (
                    <>
                        <span className={cx('bold-text')}>You</span> have paid{' '}
                        <span className={cx('bold-text')}>
                            ${formatCurrency(data?.paymentTransaction?.oraiAmount * data?.paymentTransaction?.oraiToUSD * 1.04, 2)}
                        </span>{' '}
                        to buy <span className={cx('price-text')}>{data?.paymentTransaction?.oraiAmount} ORAI</span> via Stripe.
                        Payment ID{' '}
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                copy(data?.paymentTransaction?.stripePaymentId);
                                notification.success({ message: 'Stripe Payment ID copied' });
                            }}
                            className={cx('bold-text')}
                        >
                            <CenterEllipsis size={3} text={data?.paymentTransaction?.stripePaymentId} /> <RiFileCopyLine />
                        </span>
                        . <span className={cx('bold-text')}>View Receipt</span>
                    </>
                );
                actionRow = (
                    <>
                        <div className={cx('user-avatar-wrapper')}>
                            <AvatarPlaceholder
                                address={data?.receipient?.publicAddress}
                                src={data?.receipient?.picture}
                                className={cx('avatar')}
                            />
                            {actionBadge}
                        </div>
                        <div className={cx('action')}>
                            <div className={cx('action-title')}>{actionText}</div>
                            <div className={cx('action-time')}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
                        </div>
                    </>
                );
                onClick = async () => {
                    window.open(data?.paymentTransaction?.receiptUrl, '_blank');
                    if (!data?.isRead) {
                        await readSingleNotification(data?.id, index);
                    }
                };
                break;
            case NOTIFICATION_TYPE.TOPUP_FULLFILLED:
                actionBadge = (
                    <div className={cx('action-badge', 'action-badge-white')}>
                        <OraiPriceNotificationIcon className={cx('action-badge-icon')} />
                    </div>
                );
                actionText = (
                    <>
                        <span className={cx('bold-text')}>You</span> have received{' '}
                        <span className={cx('price-text')}>{data?.paymentTransaction?.oraiAmount} ORAI</span> from Stripe Payment ID{' '}
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                copy(data?.paymentTransaction?.stripePaymentId);
                                notification.success({ message: 'Stripe Payment ID copied' });
                            }}
                            className={cx('bold-text')}
                        >
                            <CenterEllipsis size={3} text={data?.paymentTransaction?.stripePaymentId} /> <RiFileCopyLine />
                        </span>
                        . <span className={cx('bold-text')}>View on Oraiscan</span>
                    </>
                );
                actionRow = (
                    <>
                        <div className={cx('user-avatar-wrapper')}>
                            <AvatarPlaceholder
                                address={data?.receipient?.publicAddress}
                                src={data?.receipient?.picture}
                                className={cx('avatar')}
                            />
                            {actionBadge}
                        </div>
                        <div className={cx('action')}>
                            <div className={cx('action-title')}>{actionText}</div>
                            <div className={cx('action-time')}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
                        </div>
                    </>
                );
                onClick = async () => {
                    window.open(`${configEnv.REACT_APP_SCAN_URL}/txs/${data?.paymentTransaction?.txHash}`, '_blank');
                    if (!data?.isRead) {
                        await readSingleNotification(data?.id, index);
                    }
                };
                break;
            */
      case NOTIFICATION_TYPE.VERIFY_DISCORD:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-blue")}>
            <img src={BadgeIconVerifyDiscord} alt="" />
          </div>
        );
        actionText = (
          <span>
            <span>Your account has been connected to the Discord user </span>
            <span className={cx("bold-text")}>{wallet?.tagName}</span>
          </span>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;
      case NOTIFICATION_TYPE.DISCONNECT_DISCORD:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-blue")}>
            <img src={BadgeIconDisconnectDiscord} alt="" />
          </div>
        );
        actionText = (
          <span>
            <span>You have disconnected your account from the Discord user </span>
            <span className={cx("bold-text")}>{wallet?.tagName}</span>
          </span>
        );
        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );
        onClick = async () => {
          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;

      case NOTIFICATION_TYPE.SUCCESS_REPORT:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-blue")}>
            <img src={IconSuccessReport} alt="" />
          </div>
        );

        actionText = (
          <span>
            <span>The Similarity checking process for the asset</span>
            <span className={cx("bold-text")}>
              &nbsp;{data?.asset?.name}&nbsp;#{data?.asset?.id}&nbsp;
            </span>
            <span>has been done.</span>
            <span className={cx("textColorGradient")}>&nbsp;View report</span>
          </span>
        );

        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );

        onClick = async () => {
          history.push(
            `/similarity-check-report/${data?.data?.asset?.id}/${data?.data?.report?.id}`
          );

          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;

      case NOTIFICATION_TYPE.CLAIM_STAKING_REWARD:
        actionBadge = (
          <div className={cx("action-badge", "action-badge-purple")}>
            <ReceiveRoyaltyNotificationIcon className={cx("action-badge-icon")} />
          </div>
        );

        actionText = (
          <>
            You have received{" "}
            {
              <span className={cx("price-text")}>
                {data?.data?.amount / 10 ** 6} {data?.data?.denom}
              </span>
            }{" "}
            (
            <PriceToUsdDisplay
              className={cx("bold-text")}
              amount={data?.data?.amount}
              denom={data?.data?.denom}
            />
            ) reward from the
            {<span className={cx("bold-text")}> {data?.collection?.name}</span>} Staking pool
          </>
        );

        actionRow = (
          <>
            <div className={cx("user-avatar-wrapper")}>
              <AvatarPlaceholder
                address={data?.receipient?.publicAddress}
                src={data?.receipient?.picture}
                className={cx("avatar")}
              />
              {actionBadge}
            </div>
            <div className={cx("action")}>
              <div className={cx("action-title")}>{actionText}</div>
              <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
            </div>
          </>
        );

        onClick = async () => {
          window.open(`${configEnv.REACT_APP_SCAN_URL}/txs/${data?.data?.txHash}`, "_blank");

          if (!data?.isRead) {
            await readSingleNotification(data?.id, index);
          }
        };
        break;

      // case NOTIFICATION_TYPE.TOPUP_DISRUPTED:
      //     actionBadge = (
      //         <div className={cx("action-badge", "action-badge-white")}>
      //             <OraiPriceNotificationIcon className={cx("action-badge-icon")} />
      //         </div>
      //     );
      //     actionText = (
      //         <>
      //             <span className={cx("bold-text")}>You</span> have paid{" "}
      //             <span className={cx("bold-text")}>${data?.paymentTransaction?.oraiAmount * data?.paymentTransaction?.oraiToUSD}</span> to buy{" "}
      //             <span className={cx("price-text")}>{data?.paymentTransaction?.oraiAmount} ORAI</span> via Stripe.{" "}
      //             Payment ID <span onClick={(e) => {e.stopPropagation(); copy(data?.paymentTransaction?.stripePaymentId); notification.success({message:'Stripe Payment ID copied'})}} className={cx("bold-text")}><CenterEllipsis size={3} text={data?.paymentTransaction?.stripePaymentId} /> <RiFileCopyLine /></span>.{" "}
      //             <span className={cx("bold-text")}>View Receipt</span>
      //         </>
      //     );
      //     actionRow = (
      //         <>
      //             <div className={cx("user-avatar-wrapper")}>
      //                 <AvatarPlaceholder
      //                     address={data?.receipient?.publicAddress}
      //                     src={data?.receipient?.picture}
      //                     className={cx("avatar")}
      //                 />
      //                 {actionBadge}
      //             </div>
      //             <div className={cx("action")}>
      //                 <div className={cx("action-title")}>{actionText}</div>
      //                 <div className={cx("action-time")}>{getDurationAsTimeFromNow(data?.createdAt)}</div>
      //             </div>
      //         </>
      //     );
      //     onClick = async () => {
      //         window.open(data?.paymentTransaction?.receiptUrl, "_blank");
      //         if (!data?.isRead) {
      //             await readSingleNotification(data?.id, index);
      //         }
      //     };
      //     break;
      default:
        actionRow = null;
        break;
    }

    return (
      !isNil(actionRow) && (
        <div
          onClick={onClick}
          className={cx("notification-row", !data?.isRead && "notification-row-unread")}
        >
          {actionRow}
          <MoreIcon className={cx("more-icon")} />
        </div>
      )
    );
  };

  return (
    !isNil(wallet?.publicAddress) &&
    wallet?.publicAddress !== "0x" && (
      <div ref={dropdownRef} className={cx("notification")}>
        <a
          onClick={() => {
            setShowDropdown(!showDropdown);
            resetNotificationCount();
          }}
          className={cx("notification-toggle", showDropdown && "notification-toggle-active")}
        >
          {notificationCountDisplay && !isNil(notificationCount) && notificationCount !== 0 && (
            <div className={cx("notification-toggle-count")}>{notificationCount}</div>
          )}

          {isDarkTheme ? (
            <NotificationIcon className={cx("notification-toggle-icon")} />
          ) : (
            <img width={20} height={22} src={IconBell} alt="" />
          )}
        </a>
        <div
          style={{
            display: showDropdown ? "block" : "none"
          }}
          className={cx("notification-dropdown")}
        >
          <div
            className={cx("header")}
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <div className={cx("header-title")}>Notifications</div>
            <div className={cx("header-mark-read")} onClick={() => {}}>
              <img src={DoubleCheckIcon} className={cx("header-mark-read-icon")} />
              <div
                onClick={async () => {
                  let allNotificationRows =
                    notificationWrapperElementRef.current?.getElementsByClassName(
                      cx("notification-row")
                    );
                  Array.from(allNotificationRows).map((item) => {
                    item.classList.remove(cx("notification-row-unread"));
                  });
                  await callApi({
                    method: "patch",
                    url: `notifications/read-all`,
                    contentType: "application/json"
                  });
                }}
                className={cx("header-mark-read-title")}
              >
                Mark all as read
              </div>
            </div>
          </div>
          <div className={cx("body")}>
            <div
              ref={notificationWrapperElementRef}
              id="notification-scroll-div"
              className={cx("notification-wrapper")}
            >
              <InfiniteScroll
                style={{ overflow: "visible" }}
                dataLength={notifications?.length}
                next={() => {
                  setNotificationPage(notificationPage + 1);
                }}
                hasMore={true}
                loader={""}
                scrollableTarget="notification-scroll-div"
              >
                {notifications.map((item, index) => {
                  return renderNotificationInfor(item, index);
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Notification;
