import React from "react";

const TwitterIcon: React.FC<{ className: string, color?: string }> = ({ className, color = "#9A9A9A" }) => {
    return (
        <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="8" fill="#D7D7D7" />
            <path d="M13 5.40011C12.6242 5.56315 12.2267 5.67094 11.82 5.72011C12.2491 5.46377 12.5707 5.0605 12.725 4.58511C12.3218 4.82514 11.8804 4.99425 11.42 5.08511C11.1123 4.75139 10.7025 4.52925 10.2549 4.45353C9.80735 4.37781 9.34725 4.45278 8.9469 4.6667C8.5465 4.88062 8.22845 5.22137 8.0426 5.63552C7.85675 6.04968 7.81365 6.5138 7.92 6.95511C7.1047 6.91387 6.30722 6.70158 5.57932 6.33204C4.85143 5.96249 4.20942 5.44395 3.695 4.81011C3.51457 5.12519 3.41976 5.48202 3.42 5.84511C3.41936 6.1823 3.50211 6.51441 3.66088 6.81189C3.81964 7.10936 4.04951 7.36296 4.33 7.55012C4.00399 7.54122 3.68494 7.45375 3.4 7.29511V7.32011C3.40244 7.79257 3.56799 8.24967 3.86865 8.61407C4.16932 8.97852 4.58663 9.22792 5.05 9.32012C4.87163 9.37437 4.68644 9.40302 4.5 9.40512C4.37094 9.40362 4.24221 9.39187 4.115 9.37012C4.24696 9.77652 4.50231 10.1317 4.84554 10.3862C5.18877 10.6407 5.60279 10.7819 6.03 10.7901C5.3086 11.3577 4.41794 11.6675 3.5 11.6701C3.33287 11.6707 3.16587 11.6606 3 11.6401C3.93721 12.2452 5.02941 12.5665 6.145 12.5651C6.91484 12.5731 7.67855 12.4276 8.39155 12.1371C9.10455 11.8467 9.7525 11.417 10.2976 10.8734C10.8427 10.3297 11.274 9.68282 11.5663 8.97057C11.8586 8.25832 12.006 7.49497 12 6.72511C12 6.6401 12 6.55011 12 6.46011C12.3924 6.16751 12.7308 5.80882 13 5.40011Z" fill="#262626" />
        </svg>
    );
};

export default TwitterIcon;
