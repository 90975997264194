import React, { useEffect, useContext } from "react";
import cn from "classnames/bind";
import CurrencyFormatter from "src/components/common/CurrencyFormatter/CurrencyFormatter";
import styles from "./PriceDisplay.module.scss";
import NftContext from "src/components/pages/NftDetail/NftContext/NftContext";
import { isNil } from "lodash";

const cx = cn.bind(styles);

const PriceDisplay: React.FC<{
    amount: any;
    isTransaction?: boolean;
    smallClassName?: string;
    isHaveBorder?: boolean;
    zeroToNA?: boolean;
    displaySmall?: boolean;
    className?: any;
    each?: boolean;
    toAmount?: number;
    is1155?: boolean;
    notOnSale?: boolean;
    totalAmount?: boolean;
    displayIcon?: boolean;
    denom?: string;
    reverse?: boolean;
    flexRight?: boolean;
}> = ({
    amount,
    isTransaction = false,
    displaySmall = true,
    smallClassName = "",
    isHaveBorder = true,
    zeroToNA = false,
    className = "font-size-normal",
    each = false,
    toAmount,
    is1155 = false,
    notOnSale = false,
    totalAmount = false,
    displayIcon = true,
    denom,
    reverse = false,
    flexRight = false,
}) => {
    const currentAmount = amount || 0;
    const isShowToAmount = toAmount && currentAmount !== toAmount;
    //Nft context
    const { contextAction } = useContext(NftContext);

    useEffect(() => {
        if (totalAmount === true) {
            contextAction?.setTotalPrice(currentAmount);
        }
    }, [amount]);

    if (isNil(amount)) return null;

    return (
        <div className={cx("price", className)}>
            <div
                style={{ background: !isHaveBorder ? "none" : "", padding: !isHaveBorder ? 0 : "" }}
                className={cx("price-value", !isHaveBorder && "none-border-background-style")}
            >
                <span className={cx("price-value-text", reverse && "price-value-text-reverse")}>
                    {!notOnSale ? (
                        <>
                            {(!currentAmount || currentAmount === 0) && zeroToNA ? (
                                "N/A"
                            ) : (
                                <>
                                    <CurrencyFormatter
                                        displaySmall={displaySmall}
                                        smallClassName={smallClassName}
                                        amount={currentAmount}
                                        isTransaction={isTransaction}
                                        each={each}
                                        denom={denom}
                                        displayIcon={displayIcon}
                                        flexRight={flexRight}
                                    />
                                    {isShowToAmount ? (
                                        <span className={cx("price-value-text")} style={{ fontSize: 20 }}>
                                            {" - "}
                                        </span>
                                    ) : null}
                                    {isShowToAmount ? (
                                        <CurrencyFormatter
                                            displaySmall={displaySmall}
                                            smallClassName={smallClassName}
                                            amount={toAmount}
                                            isTransaction={isTransaction}
                                            each={each}
                                            denom={denom}
                                            displayIcon={displayIcon}
                                            flexRight={flexRight}
                                        />
                                    ) : null}
                                </>
                            )}
                        </>
                    ) : null}
                </span>
            </div>
        </div>
    );
};

export default PriceDisplay;
