import React from "react";

const CollectionNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M5.38721 3.14783V9.89783C5.38721 10.4378 5.1672 10.9328 4.8172 11.2928L4.79721 11.3128C4.75221 11.3578 4.70221 11.4028 4.65721 11.4378C4.50721 11.5678 4.3372 11.6678 4.1622 11.7378C4.1072 11.7628 4.05221 11.7828 3.99721 11.8028C3.80221 11.8678 3.59221 11.8978 3.38721 11.8978C3.25221 11.8978 3.11721 11.8828 2.98721 11.8578C2.92221 11.8428 2.85721 11.8278 2.79221 11.8078C2.71221 11.7828 2.63721 11.7578 2.56221 11.7228C2.56221 11.7178 2.56221 11.7178 2.55721 11.7228C2.41721 11.6528 2.28221 11.5728 2.15721 11.4778L2.15221 11.4728C2.08721 11.4228 2.02721 11.3728 1.97221 11.3128C1.91721 11.2528 1.86221 11.1928 1.80721 11.1278C1.71221 11.0028 1.63221 10.8678 1.56221 10.7278C1.56721 10.7228 1.56721 10.7228 1.56221 10.7228C1.56221 10.7228 1.56221 10.7178 1.55721 10.7128C1.52721 10.6428 1.5022 10.5678 1.4772 10.4928C1.4572 10.4278 1.4422 10.3628 1.4272 10.2978C1.4022 10.1678 1.38721 10.0328 1.38721 9.89783V3.14783C1.38721 2.39783 1.88721 1.89783 2.63721 1.89783H4.13721C4.88721 1.89783 5.38721 2.39783 5.38721 3.14783Z'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M11.3872 9.14783V10.6478C11.3872 11.3978 10.8872 11.8978 10.1372 11.8978H3.38721C3.59221 11.8978 3.80221 11.8678 3.99721 11.8028C4.05221 11.7828 4.1072 11.7628 4.1622 11.7378C4.3372 11.6678 4.50721 11.5678 4.65721 11.4378C4.70221 11.4028 4.75221 11.3578 4.79721 11.3128L4.8172 11.2928L8.21721 7.89783H10.1372C10.8872 7.89783 11.3872 8.39783 11.3872 9.14783Z'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M2.79205 11.8078C2.49205 11.7178 2.20706 11.5528 1.97206 11.3128C1.73206 11.0778 1.56705 10.7928 1.47705 10.4928C1.67205 11.1178 2.16705 11.6128 2.79205 11.8078Z'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M9.57239 6.54283L8.21739 7.89784L4.81738 11.2928C5.16738 10.9328 5.38739 10.4378 5.38739 9.89784V5.06783L6.74239 3.71284C7.27239 3.18284 7.98239 3.18284 8.51239 3.71284L9.57239 4.77284C10.1024 5.30284 10.1024 6.01283 9.57239 6.54283Z'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M3.38721 10.3978C3.66335 10.3978 3.88721 10.174 3.88721 9.89783C3.88721 9.62168 3.66335 9.39783 3.38721 9.39783C3.11106 9.39783 2.88721 9.62168 2.88721 9.89783C2.88721 10.174 3.11106 10.3978 3.38721 10.3978Z'
                stroke='currentColor'
                stroke-width='0.5'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default CollectionNotificationIcon;
