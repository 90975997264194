import React from "react";

const BurnNotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1.58496 4.61783L5.99995 7.17282L10.3849 4.63281'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M6 11.7028V7.16782' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' />
            <path
                d='M10.8048 5.48283V8.31285C10.8048 8.33785 10.8048 8.35782 10.7998 8.38282C10.4498 8.07782 9.99982 7.89784 9.49982 7.89784C9.02982 7.89784 8.59482 8.06284 8.24982 8.33784C7.78982 8.70284 7.49982 9.26784 7.49982 9.89784C7.49982 10.2728 7.60481 10.6278 7.78981 10.9278C7.83481 11.0078 7.88981 11.0828 7.94981 11.1528L7.03482 11.6578C6.46482 11.9778 5.53481 11.9778 4.96481 11.6578L2.29482 10.1778C1.68982 9.84284 1.19482 9.00285 1.19482 8.31285V5.48283C1.19482 4.79283 1.68982 3.95284 2.29482 3.61784L4.96481 2.13783C5.53481 1.81783 6.46482 1.81783 7.03482 2.13783L9.70482 3.61784C10.3098 3.95284 10.8048 4.79283 10.8048 5.48283Z'
                stroke='currentColor'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M11.5 9.89783C11.5 10.2728 11.395 10.6278 11.21 10.9278C11.105 11.1078 10.97 11.2678 10.815 11.3978C10.465 11.7128 10.005 11.8978 9.5 11.8978C8.77 11.8978 8.13499 11.5078 7.78999 10.9278C7.60499 10.6278 7.5 10.2728 7.5 9.89783C7.5 9.26783 7.79 8.70283 8.25 8.33783C8.595 8.06283 9.03 7.89783 9.5 7.89783C10.605 7.89783 11.5 8.79283 11.5 9.89783Z'
                stroke='currentColor'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.0346 10.4178L8.97461 9.36282'
                stroke='currentColor'
                stroke-width='0.5'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M10.0248 9.37784L8.96484 10.4328'
                stroke='currentColor'
                stroke-width='0.5'
                stroke-miterlimit='10'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    );
};

export default BurnNotificationIcon;
