// @ts-nocheck
import React, { useState, useRef, useEffect, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useMutate } from "restful-react";
import queryString from "query-string";
import cn from "classnames/bind";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { IWallet } from "src/types/redux";
import { useWeb3React } from "@web3-react/core";
import { RiErrorWarningFill } from "react-icons/ri";
import MetaMaskOnboarding from "@metamask/onboarding";
import ButtonAntd from "antd/lib/button";

// utils & consts
import { getEthBalance } from "src/utils/web3Service";
import useToken from "src/utils/token";
import { getWalletBalance, getWalletAIRIBalance } from "src/utils/balance";
import { isApproved } from "src/utils/approveAccount";
import { IsVerifiedArtist } from "src/utils/checkWallet";
import { themeIds } from "src/consts/theme";
import { connectorsByName, ConnectorNames } from "src/consts/connectors";
import { useCheckMobileScreen, useConnectKeplrWallet, useEagerConnect, useInactiveListener } from "src/utils/hooks";
import configEnv from "../../../utils/config.env";
// component
import Modal from "src/components/common/Modal";
import Payment from "src/components/pages/NftDetail/Payment";
import NftContext from "src/components/pages/NftDetail/NftContext/NftContext";
import CurrencyFormatter from "src/components/common/CurrencyFormatter/CurrencyFormatter";
import AvatarPlaceholder from "src/components/common/AvatarPlaceholder/AvatarPlaceholder";
import Button from "src/components/common/Button/Button";
import CenterEllipsis from "src/components/common/CenterEllipsis/CenterEllipsis";
import ButtonWalletWrap from "./ButtonWalletWrap";
import SSOWidget from "./SSOWidget";
import PriceDisplay from "../PriceDisplay/PriceDisplay";
import TopUpModal from "../TopUpModal";
import PriceBNBDisplay from "./PriceBNBDisplay";
import PriceAIRIDisplay from "./PriceAIRIDisplay";

// api & store
import { callApi } from "src/api";
import { setWalletInformation, selectWallet } from "src/store/slices/walletSlice";
import { showAlertBox } from "src/store/slices/alertSlice";
import { selectThemeId, setActiveThemeId } from "src/store/slices/activeThemeSlice";

// icons & image
import IconPerson from "src/assets/icons/icon-person.svg";
import IconPersonDark from "src/assets/icons/icon-person-dark.svg";
import AIRightIcon from "src/assets/images/aiRight-logo-img.svg";
import Oraichain_Icon_Image_Light from "src/assets/images/Oraichain_Icon_Image_Light.svg";
import Oraichain_Icon_Image_Dark from "src/assets/images/Oraichain_Icon_Image_Dark.svg";
import active_wallet_dark from "src/assets/icons/Wallet/active_wallet_dark.svg";
import active_wallet_light from "src/assets/icons/Wallet/active_wallet_light.svg";
import approve_contract_dark from "src/assets/icons/Wallet/approve_contract_dark.svg";
import approve_contract_light from "src/assets/icons/Wallet/approve_contract_light.svg";
import copy_wallet_dark from "src/assets/icons/Wallet/copy_wallet_dark.svg";
import copy_wallet_light from "src/assets/icons/Wallet/copy_wallet_light.svg";
import sign_out_dark from "src/assets/icons/Wallet/sign_out_dark.svg";
import sign_out_light from "src/assets/icons/Wallet/sign_out_light.svg";
import bnb_dark from "src/assets/icons/Wallet/bnb-dark.svg";
import bnb_light from "src/assets/icons/Wallet/bnb-light.svg";
import BscConnectIcon from "src/assets/icons/bsc.svg";
import OwalletIcon from "src/assets/icons/Wallet/owallet.svg";
import MetaMaskIcon from "src/assets/icons/fox.svg";
import WalletConnectIcon from "src/assets/icons/wallet-connect.svg";
import IconDiscord from "src/assets/icons/Icon-discord-white.svg";
import MoonIcon from "src/assets/icons/MoonIcon";
import DisconnectDiscordImage from "src/assets/images/disconnect-connect-discord.svg";
import IconCheckAccount from "src/assets/icons/CheckAccount.svg";
import DropdownIcon from "src/assets/icons/Wallet/Dropdown.svg";
import DropdownDarkIcon from "src/assets/icons/Wallet/Dropdown-dark.svg";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

// styles
import styles from "./ConnectWalletButton.module.scss";
import { Denom } from "src/consts/denom";
import MyProfileIcon from "src/assets/icons/MyProfileIcon";
import { notification } from "antd";
import axios from "axios";
const cx = cn.bind(styles);

const origins = ["https://staging.sso.orai.io", "https://sso.orai.io", "http://localhost:3000"];

const ConnectWalletButton: React.FC<{
    walletAddress: string;
    erc20Address: string;
    oraiAddress: string;
    isErc20: bool;
    onConnectWallet?: Function;
    setMobileWalletInforDropdown: Function;
    setOpenVerifyDialog?: Function;
    setOpenApproveDialog?: Function;
    dataPriceBNB?: any;
}> = ({
    walletAddress,
    erc20Address,
    oraiAddress,
    isErc20,
    onConnectWallet,
    setMobileWalletInforDropdown,
    setOpenVerifyDialog,
    setOpenApproveDialog,
    dataPriceBNB,
}) => {
    //nft context
    const { contextData, contextAction } = useContext(NftContext);
    const location = useLocation();
    const dispatch = useDispatch();
    const isMobileScreenLTE = useCheckMobileScreen(1200);
    const { connectKeplrWallet } = useConnectKeplrWallet();
    const [ethBalance, setEthBalance] = useState(0);
    const walletInformation = (info) => dispatch(setWalletInformation(info));
    const [open, setOpen] = useState(false);
    const [isApproveContract, setIsApproveContract] = useState(true);
    const [refetchAmount, setRefetchAmount] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const history = useHistory<{ from: "" }>();
    const wallet = useSelector<IWallet>(selectWallet);
    const activeThemeId = useSelector(selectThemeId);
    const isDarkTheme = activeThemeId === themeIds.DARK;
    const iframeRef = useRef() as any;
    const walletDropdownRef = useRef();
    const walletRef = useRef();
    const [loadingDisconnect, setLoadingDisconnect] = useState<boolean>(false);
    const [activeMoreOptions, setActiveMoreOptions] = useState(false);
    const [activeMoreOptionsBSCWallet, setActiveMoreOptionsBSCWallet] = useState(false);

    // more options
    const MORE_OPTIONS = [
        {
            key: "copy_wallet",
            value: "Copy wallet address",
            icon: isDarkTheme ? copy_wallet_dark : copy_wallet_light,
            onClick: () => {
                copy(oraiAddress);
                notification.success({ message: "Copied!" });
            },
            isDisable: false,
        },
        {
            key: "active_wallet",
            value: "Activate your wallet",
            icon: isDarkTheme ? active_wallet_dark : active_wallet_light,
            onClick: () => {
                setOpenVerifyDialog(true);
            },
            isDisable: wallet?.isEmailVerified,
            extraIcon: !wallet?.isEmailVerified && (
                <RiErrorWarningFill className={cx("warning-icon")} style={{ color: isDarkTheme ? "#ffc700" : "#e9ad00" }} />
            ),
        },
        {
            key: "approve",
            value: "Approve the NFT Market contract",
            icon: isDarkTheme ? approve_contract_dark : approve_contract_light,
            onClick: () => {
                setOpenApproveDialog(true);
            },
            isDisable: isApproveContract,
            extraIcon: !isApproveContract && <RiErrorWarningFill className={cx("warning-icon")} style={{ color: "#FF3A3A" }} />,
        },
        {
            key: "sign_out",
            value: "Sign out",
            icon: isDarkTheme ? sign_out_dark : sign_out_light,
            onClick: () => logoutOraiWallet(),
            isDisable: false,
        },
    ];

    const MORE_OPTIONS_BSC_WALLET = [
        {
            key: "copy_wallet",
            value: "Copy wallet address",
            icon: isDarkTheme ? copy_wallet_dark : copy_wallet_light,
            onClick: () => {
                copy(erc20Address);
                notification.success({ message: "Copied!" });
            },
        },
        {
            key: "sign_out",
            value: "Sign out",
            icon: isDarkTheme ? sign_out_dark : sign_out_light,
            onClick: () => deactivate(),
        },
    ];

    //Check approve market contract
    const isApprovedFunc = async () => {
        const result = await isApproved(wallet?.publicAddress, "all");
        setIsApproveContract(result);
    };
    useEffect(() => {
        if (wallet?.publicAddress && wallet?.publicAddress !== "0x") {
            isApprovedFunc();
        }
    }, [wallet?.publicAddress, contextData.refreshApproval]);

    const { balance, publicAddress: address, airiBalance } = wallet;
    const isConnected = walletAddress && walletAddress !== "0x";

    useEffect(() => {
        if (erc20Address && erc20Address !== "0x") {
            getEthBalance(erc20Address).then((res) => {
                setEthBalance((res / 10 ** 18).toFixed(2));
            });
        }
    }, [erc20Address]);

    const { saveToken, deleteToken } = useToken();

    //TopUP
    const handleTopUp = () => {
        contextAction.setVisibleSelectTopUp(true);
    };

    // const context = useWeb3React();
    // const { connector, activate, error, active, library, account } = context;
    // const [activatingConnector, setActivatingConnector] = useState();
    // const childKeyParam: any = queryString.parse(history.location.search)?.childKey;
    const [isKeplr, setIsKeplr] = useState(false);

    const isOraiConnected = wallet?.publicAddress?.startsWith("orai");

    const windowRef: any = useRef();

    const { mutate: authenticateSSO } = useMutate({
        verb: "POST",
        path: `/auth/sso-authentication`,
        base: configEnv.REACT_APP_BASE_URL,
    });

    useEffect(() => {
        const checkKeplr = async () => {
            if (await window.Keplr.getKeplr()) setIsKeplr(true);
        };
        checkKeplr();
    }, []);

    useEffect(() => {
        document?.addEventListener("mousedown", handleClickOutside);
        return () => {
            document?.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (walletDropdownRef && !walletDropdownRef?.current?.contains?.(event?.target)) {
            setOpen(false);
            if (walletRef?.current) {
                walletRef.current.style.opacity = 0;
                walletRef.current.style.visibility = "hidden";
            }
        }
    };

    const authenticationSSO = async () => {
        window.addEventListener(
            "message",
            async (event) => {
                if (!origins.includes(event.origin)) return;

                const receivedData = event.data;

                if (typeof receivedData === "string" && receivedData === "ready") {
                    return;
                }

                if (typeof receivedData === "object") {
                    return;
                }

                if (windowRef?.current) {
                    windowRef.current?.close();
                    const parsedData = JSON.parse(receivedData);

                    const token = parsedData?.token;
                    if (token) {
                        const body = {
                            accessToken: token,
                        };

                        const result = await authenticateSSO(body);

                        const { balance } = await getWalletBalance(result.publicAddress);

                        const { data } = await getWalletAIRIBalance(result.publicAddress);

                        saveToken(result?.token?.accessToken);
                        walletInformation({
                            ...result,
                            username: result.username,
                            picture: result.picture,
                            publicAddress: result.publicAddress,
                            balance: balance?.amount ?? 0,
                            airiBalance: data?.balance ?? 0,
                        });
                        localStorage.setItem("isOraiWallet", "true");
                        if (history.location.state && history.location.state.from) {
                            history.push(history.location.state.from);
                        } else {
                            // setShow(false);
                        }
                    }
                }
            },
            false
        );

        return;
    };

    useEffect(() => {
        // Listen to event from sub-window
        authenticationSSO();

        return () => {
            window.removeEventListener("message", () => {});
        };
    }, []);

    const timeoutRef = useRef();
    const showDropdownWallet = () => {
        if (walletRef?.current) {
            walletRef.current.style.opacity = 1;
            walletRef.current.style.visibility = "visible";
        }
    };

    const hideDropdownWallet = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };

    const logoutOraiWallet = () => {
        // clear SSO --> Update: Change to Keplr wallet
        // iframeRef.current.forceLogout();

        setTimeout(() => {
            // clear redux values
            deleteToken();
            walletInformation({
                publicAddress: "0x",
                picture: "",
                username: "",
                childKey: "",
            });
            localStorage.removeItem("isOraiWallet");
            // check if the use is under the /profile router => push to home
            if (location.pathname.match(/\/profile/gi)) {
                history.push("/");
            }
        }, 500);
    };

    const toggleMode = () => {
        const themeId = activeThemeId === themeIds.LIGHT ? themeIds.DARK : themeIds.LIGHT;
        dispatch(setActiveThemeId(themeId));
    };

    const alertBox = (payload) => dispatch(showAlertBox(payload));

    // ERC20 wallet connect
    const context = useWeb3React();
    const { connector, activate, error, active, library, account, deactivate } = context;
    const [activatingConnector, setActivatingConnector] = useState();
    const onboarding = useRef();

    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);

    useEffect(() => {
        if (error) {
            if (connector instanceof WalletConnectConnector) {
                const walletConnector = connector as WalletConnectConnector;
                walletConnector.walletConnectProvider = null;
            }
        }
    }, [error]);

    useEffect(() => {
        if (!onboarding.current) {
            onboarding.current = new MetaMaskOnboarding();
        }
    }, []);

    const triedEager = useEagerConnect();

    useInactiveListener(!triedEager || !!activatingConnector);

    const connectErc20Wallet = async (name: any) => {
        const currentConnector: any = connectorsByName[name];
        setActivatingConnector(currentConnector);

        if (ConnectorNames.WalletConnect === name) {
            return activate(connectorsByName[name]);
        }

        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            try {
                await activate(connectorsByName[name]).then((val) => {
                    console.log("Connecting to MetaMask", val);
                });
            } catch (error) {
                console.log("Error on connecting: ", error);
            }
        } else {
            // start
            onboarding.current.startOnboarding();
        }
    };

    useEffect(() => {
        try {
            if (!address) {
                return;
            }
            const url = configEnv.REACT_APP_WEBSOCKET_URL || `wss://rpc.orai.io/websocket`;
            const socket = new WebSocket(url);
            socket.onopen = () => {
                socket.send(
                    JSON.stringify({
                        jsonrpc: "2.0",
                        method: "subscribe",
                        params: [`tm.event='Tx' AND transfer.recipient='${address}'`],
                        id: 1,
                    })
                );
                socket.send(
                    JSON.stringify({
                        jsonrpc: "2.0",
                        method: "subscribe",
                        params: [`tm.event='Tx' AND transfer.sender='${address}'`],
                        id: 1,
                    })
                );
            };

            socket.onmessage = onMessage;
            return () => {};
        } catch (error) {
            console.log(error);
        }
    }, [address]);

    const onMessage = (res: any) => {
        const data = JSON.parse(res.data);
        const isTransfer = data?.result?.data?.type === "tendermint/event/Tx";
        const events = data?.result?.events;
        const recipient = events?.["transfer.recipient"]?.[0];
        if (isTransfer && recipient === address) {
            const amount = `${events?.["transfer.amount"]}`;
            const value = +amount?.replace?.(/[^0-9\.]+/g, "");
            setRefetchAmount((num) => num + 1);
            alertBox({
                title: "Received ORAI",
                message: (
                    <>
                        <div>
                            Amount <CurrencyFormatter amount={value} />
                        </div>
                        <div>From {events?.["transfer.sender"]}</div>
                    </>
                ),
            });
        }

        const sender = events?.["transfer.sender"]?.[0];
        if (sender === address) {
            setRefetchAmount((num) => num + 1);
        }
    };

    useEffect(() => {
        if (isConnected) {
            getWalletBalance(address).then(({ balance }) => {
                walletInformation({
                    balance: balance?.amount ?? 0,
                });
            });

            getWalletAIRIBalance(address).then(({ data }) => {
                walletInformation({
                    airiBalance: data?.balance ?? 0,
                });
            });
        }
    }, [address, refetchAmount]);

    const onOpenProfile = () => {
        onConnectWallet();
        history.push(`/profile/${wallet?.publicAddress}`);
    };

    // connect discord
    const DisconnectDiscord = async () => {
        let urlConfirmDiscord = "/providers/disconnect-discord";
        setLoadingDisconnect(true);

        try {
            const result: any = await callApi({
                method: "post",
                url: urlConfirmDiscord,
                contentType: "application/json",
            });

            walletInformation({
                ...wallet,
                isDiscordVerified: false,
            });

            history.push(`/profile/${wallet?.publicAddress}`);
            setLoadingDisconnect(false);
        } catch (error) {
            setLoadingDisconnect(false);
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        DisconnectDiscord();
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleConnectDiscord = useCallback(() => {
        if (wallet?.isDiscordVerified) {
            return showModal();
        }

        return (window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${configEnv.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${configEnv.REACT_APP_REDIRECT_URI_DISCORD}&response_type=token&scope=identify`);
    }, [window, wallet, showModal]);

    const renderModalDisconnectDiscord = useMemo(() => {
        const renderContentModal = () => {
            return (
                <>
                    <img className={cx("disconnect-connect-discord")} src={DisconnectDiscordImage} alt='disconnect-connect-discord' />
                    <div className={cx("text")}>Are you sure?</div>
                    <div className={cx("sub-text")}>
                        If you disconnect, your current memberships in the <br />
                        aiRight verified channels will expire, you will be <br />
                        removed from them!
                    </div>
                </>
            );
        };

        const renderFooter = () => {
            return (
                <div style={{ display: "flex" }}>
                    <ButtonAntd
                        onClick={handleCancel}
                        style={{
                            background: "#E7EAF2",
                            color: "#505665",
                        }}
                    >
                        Cancel
                    </ButtonAntd>
                    <ButtonAntd
                        loading={loadingDisconnect}
                        onClick={handleOk}
                        style={{
                            background: "#E7EAF2",
                            color: "#FF3A3A",
                        }}
                    >
                        Yes, disconnect
                    </ButtonAntd>
                </div>
            );
        };

        return (
            <Modal
                footer={renderFooter()}
                onCancel={handleCancel}
                size='xs'
                visible={isModalVisible}
                width={400}
                okText={"Ok"}
                cancelText={"Cancel"}
                confirmLoading={true}
            >
                {renderContentModal()}
            </Modal>
        );
    }, [isModalVisible, loadingDisconnect]);

    const toggleMoreOptionsMenu = () => {
        return setActiveMoreOptions((prevState) => !prevState);
    };

    const toggleMoreOptionsBSCWallet = () => {
        return setActiveMoreOptionsBSCWallet((prevState) => !prevState);
    };

    const handleOnClickMyProfile = useCallback(() => {
        return history.push(`/profile/${wallet?.publicAddress}`);
    }, [history, wallet]);

    const MobileWalletButton = useCallback(() => {
        return (
            <>
                <div className={cx("profile")}>
                    <div id='profile-dropdown' className={cx("nav-dropdown", "profile-dropdown")}>
                        <div
                            className={cx("profile-button")}
                            onClick={() => {
                                setMobileWalletInforDropdown(true);
                            }}
                        >
                            {!isConnected ? (
                                <img src={isDarkTheme ? IconPersonDark : IconPerson} alt='person' />
                            ) : (
                                <>
                                    {!wallet?.isEmailVerified || !isApproveContract ? (
                                        <div className={cx("profile-button-status")}>
                                            <RiErrorWarningFill
                                                className={cx("warning-icon")}
                                                style={{
                                                    color: !isApproveContract ? "#FF3A3A" : "#FFC700",
                                                }}
                                            />
                                        </div>
                                    ) : null}

                                    <AvatarPlaceholder
                                        className={cx(isErc20 ? "avatar-erc20" : "avatar")}
                                        address={walletAddress}
                                        src={isErc20 ? BscConnectIcon : wallet?.picture}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }, [
        isApproveContract,
        isConnected,
        isDarkTheme,
        isErc20,
        setMobileWalletInforDropdown,
        wallet?.isEmailVerified,
        wallet?.picture,
        walletAddress,
    ]);

    const DesktopWalletDropdown = () => {
        return (
            <div
                onMouseEnter={() => {
                    setOpen(true);
                    if (walletRef?.current) {
                        walletRef.current.style.opacity = 1;
                        walletRef.current.style.visibility = "visible";
                    }
                }}
                ref={walletDropdownRef}
                className={cx("profile-dropdown-content")}
            >
                <div
                    className={cx("header")}
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div className={cx("header-title")}>My account</div>
                    <div
                        style={{ cursor: "pointer" }}
                        className={cx("header-title")}
                        onClick={() => {
                            setOpen(false);
                            if (walletRef?.current) {
                                walletRef.current.style.opacity = 0;
                                walletRef.current.style.visibility = "hidden";
                            }
                        }}
                    >
                        Close
                    </div>
                </div>
                <div className={cx("body")}>
                    {oraiAddress && oraiAddress !== "0x" ? (
                        <div style={{ border: "none" }} className={cx("user")}>
                            <div className={cx("wrapper-wallet")}>
                                <ButtonWalletWrap>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                        onClick={() => {
                                            return toggleMoreOptionsMenu();
                                        }}
                                    >
                                        <div className={cx("user-infor")}>
                                            <AvatarPlaceholder
                                                src={wallet?.picture}
                                                address={wallet?.publicAddress}
                                                className={cx("avatar")}
                                                onClick={onOpenProfile}
                                            />
                                            <div className={cx("balance")}>
                                                <div className={cx("balance-title")}>
                                                    <span>{wallet?.username}</span>
                                                    {IsVerifiedArtist(wallet?.publicAddress) && <img src={IconCheckAccount} alt='' />}
                                                </div>
                                                <div className={cx("balance-address")}>
                                                    <CenterEllipsis
                                                        size={6}
                                                        className={cx("contact-address-value")}
                                                        text={oraiAddress ?? "0x"}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {!wallet?.isEmailVerified || !isApproveContract ? (
                                            <div className={cx("")}>
                                                <RiErrorWarningFill
                                                    className={cx("warning-icon")}
                                                    style={{
                                                        color: !isApproveContract ? "#FF3A3A" : "#FFC700",
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <img width={20} height={20} src={isDarkTheme ? DropdownDarkIcon : DropdownIcon} />
                                        )}
                                    </div>
                                </ButtonWalletWrap>

                                {activeMoreOptions ? (
                                    <div className={cx("menu-more-options")}>
                                        {MORE_OPTIONS.map((item) => {
                                            return (
                                                <div
                                                    className={cx("menu-more-options-item", item.isDisable && "disable")}
                                                    onClick={() => {
                                                        if (item.isDisable) return;

                                                        return item.onClick();
                                                    }}
                                                >
                                                    <div className={cx("left-wrap")}>
                                                        <img className={cx("icon")} src={item.icon} />
                                                        <div className={cx("value")}>{item.value}</div>
                                                    </div>
                                                    {item?.extraIcon}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className={cx("menu-wrapper")}>
                                        <div className={cx("price-wrapper")}>
                                            <div className={cx("price-wrapper-airight")}>
                                                <div className={cx("left")}>
                                                    <img src={AIRightIcon} className={cx("icon")} />
                                                    <PriceDisplay
                                                        className={cx("price-value-text")}
                                                        isHaveBorder={false}
                                                        amount={airiBalance}
                                                        isTransaction={true}
                                                        denom={Denom.AIRI}
                                                        displayIcon={false}
                                                        reverse={true}
                                                    />

                                                    {/* <PriceAIRIDisplay amount={airiBalance} /> */}
                                                </div>
                                                {/* <div className={cx("top-up")} onClick={handleTopUp}>
                                                    Top up
                                                </div> */}
                                            </div>

                                            <div className={cx("price-wrapper-orai")}>
                                                <div className={cx("left")}>
                                                    <img
                                                        src={isDarkTheme ? Oraichain_Icon_Image_Dark : Oraichain_Icon_Image_Light}
                                                        className={cx("icon")}
                                                    />
                                                    <PriceDisplay
                                                        className={cx("price-value-text")}
                                                        isHaveBorder={false}
                                                        amount={balance}
                                                        isTransaction={true}
                                                        denom={Denom.ORAI}
                                                        displayIcon={false}
                                                        reverse={true}
                                                    />
                                                </div>
                                                <div className={cx("top-up")} onClick={handleTopUp}>
                                                    Top up
                                                </div>
                                            </div>
                                        </div>

                                        <div className={cx("button-connect-to-discord")} onClick={handleConnectDiscord}>
                                            <img className={cx("item-icon")} src={IconDiscord} alt='' width={18} height={19} />
                                            {wallet?.isDiscordVerified ? (
                                                <span>Disconnect {wallet?.tagName || wallet?.discord}</span>
                                            ) : (
                                                <span>Connect to Discord</span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>

                            {contextData.visibleSelectTopUp && <TopUpModal setVisibleTopUp={contextAction.setVisibleSelectTopUp} />}
                            <Payment />
                        </div>
                    ) : null}

                    {oraiAddress && oraiAddress !== "0x" ? null : (
                        <div className={cx("user")} style={{ border: "none", paddingBottom: 0 }}>
                            <div className={cx("wallet-title-wrapper")}>
                                <div className={cx("wallet-name")}>Creating, selling and collecting artworks</div>
                            </div>
                        </div>
                    )}

                    {/* <div
                        style={{
                            display: oraiAddress && oraiAddress !== "0x" ? "none" : "block",
                            paddingRight: 20,
                            paddingLeft: 20,
                        }}
                    >
                        <SSOWidget
                            componentRef={iframeRef}
                            type='inline'
                            text='Connect Oraichain Wallet'
                            buttonStyle={{
                                height: "54px",
                                display: "flex",
                                flexDirection: "row",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                lineHeight: "150%",
                                color: "#f6f7fb",
                                borderRadius: "8px",
                                border: isDarkTheme ? "1px solid #505665" : "1px solid #BDC3D3",
                                background: isDarkTheme ? "#3C404D" : "#E7EAF2",
                                width: "100%",
                                padding: "0px",
                            }}
                        />
                    </div> */}

                    {oraiAddress && oraiAddress !== "0x" ? null : (
                        <div style={{ border: "none", padding: 0, marginTop: "16px" }} className={cx("user")}>
                            <div className={cx("connect-wallet", "connect-orai", "connect-wallet-keplr")} onClick={connectKeplrWallet}>
                                <img className={cx("connect-wallet-icon")} src={OwalletIcon} alt='' /> Connect OWallet
                            </div>
                        </div>
                    )}

                    {erc20Address && erc20Address !== "0x" ? (
                        <div className={cx("bsc-wallet-wrapper")}>
                            <div className={cx("container")}>
                                <ButtonWalletWrap>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                        }}
                                        onClick={toggleMoreOptionsBSCWallet}
                                    >
                                        <div className={cx("user-infor")} onClick={toggleMoreOptionsBSCWallet}>
                                            <AvatarPlaceholder
                                                src={MetaMaskIcon}
                                                address={wallet?.publicAddress}
                                                className={cx("avatar-erc20")}
                                                onClick={() => {
                                                    history.push(`/profile/${wallet?.publicAddress}?activeTab=bsc-list`);
                                                }}
                                            />
                                            <div className={cx("balance")}>
                                                <div className={cx("balance-title")}>BSC Wallet</div>
                                                <CenterEllipsis
                                                    size={6}
                                                    className={cx("contact-address-value")}
                                                    text={erc20Address ?? "0x"}
                                                />
                                            </div>
                                        </div>
                                        <img src={isDarkTheme ? DropdownDarkIcon : DropdownIcon} />
                                    </div>
                                </ButtonWalletWrap>

                                {activeMoreOptionsBSCWallet ? (
                                    <div className={cx("more-options-bsc-wallet")}>
                                        {MORE_OPTIONS_BSC_WALLET.map((item) => {
                                            return (
                                                <div className={cx("menu-more-options-item")} onClick={item.onClick}>
                                                    <div className={cx("left-wrap")}>
                                                        <img className={cx("icon")} src={item.icon} />
                                                        <div className={cx("value")}>{item.value}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className={cx("balance-display")}>
                                        <div className={cx("left-wrap")}>
                                            <img src={isDarkTheme ? bnb_dark : bnb_light} />
                                            <div className={cx("balance")}>
                                                <PriceBNBDisplay ethBalance={ethBalance} dataPriceBNB={dataPriceBNB} />
                                            </div>
                                        </div>
                                        <div className={cx("top-up")} onClick={handleTopUp}>
                                            Top up
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className={cx("user")} style={{ paddingBottom: 0 }}>
                            <div className={cx("wallet-title-wrapper")}>
                                <div className={cx("wallet-name-bsc-wallet")}>BSC WALLET</div>
                                <div className={cx("wallet-sub-title")}>Only used for farming</div>
                            </div>
                            <div
                                className={cx("connect-wallet", "connect-metamask")}
                                onClick={() => connectErc20Wallet(ConnectorNames.MetaMask)}
                            >
                                <img className={cx("connect-wallet-icon")} src={MetaMaskIcon} alt='' /> Connect Metamask
                            </div>
                            <div
                                className={cx("connect-wallet", "connect-wallet-connect")}
                                onClick={() => connectErc20Wallet(ConnectorNames.WalletConnect)}
                            >
                                <img className={cx("connect-wallet-icon")} src={WalletConnectIcon} alt='' /> Wallet Connect
                            </div>
                        </div>
                    )}

                    <div className={cx("user")}>
                        {isConnected && (
                            <div className={cx("my-profile")} onClick={handleOnClickMyProfile}>
                                <MyProfileIcon className={cx("icon")} />
                                <div>My profile</div>
                            </div>
                        )}

                        <div className={cx("darkmode")}>
                            <div className={cx("darkmode-title")}>
                                <MoonIcon className={cx("darkmode-title-icon")} />
                                <div className={cx("darkmode-title-text")}>Dark theme</div>
                            </div>
                            <div
                                onClick={toggleMode}
                                className={cx(
                                    "darkmode-toggle",
                                    activeThemeId === themeIds.DARK ? "darkmode-toggle-dark" : "darkmode-toggle-light"
                                )}
                            >
                                <div
                                    className={cx(
                                        activeThemeId === themeIds.DARK && "darkmode-toggle-button-active",
                                        "darkmode-toggle-button"
                                    )}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const DesktopWalletButton = useMemo(() => {
        return (
            <div onMouseEnter={showDropdownWallet} onMouseLeave={hideDropdownWallet} className={cx("profile")}>
                <div id='profile-dropdown' className={cx("nav-dropdown", "profile-dropdown")}>
                    {!isConnected ? (
                        <Button
                            border={true}
                            className={cx("profile-button", !isDarkTheme && "profile-button-border")}
                            variant='gradient'
                            text='Connect wallet'
                            textColor={isDarkTheme ? "#F6F7FB" : "#009CFD"}
                        />
                    ) : (
                        <div>
                            <Button
                                className={cx("profile-button")}
                                icon={
                                    <AvatarPlaceholder
                                        className={cx(isErc20 ? "avatar-erc20" : "avatar")}
                                        address={walletAddress}
                                        src={isErc20 ? BscConnectIcon : wallet?.picture}
                                    />
                                }
                                variant='gradient'
                                text={`${walletAddress.slice(0, 4)}...${walletAddress.slice(walletAddress.length - 4)}`}
                                displayWarning={!wallet?.isEmailVerified || !isApproveContract}
                                colorWarning={!isApproveContract ? "#FF3A3A" : "#FFC700"}
                                textColor={isDarkTheme ? "#F6F7FB" : "#505665"}
                            />
                        </div>
                    )}
                    <div className={cx("wallet-popup")} ref={walletRef}>
                        <DesktopWalletDropdown />
                    </div>
                </div>
            </div>
        );
    }, [
        isConnected,
        activeThemeId,
        contextData.visibleSelectTopUp,
        isApproveContract,
        erc20Address,
        wallet,
        isDarkTheme,
        activeMoreOptions,
        setOpenVerifyDialog,
        walletRef,
        activeMoreOptionsBSCWallet,
        isKeplr,
        walletAddress,
        iframeRef,
        ethBalance,
    ]);

    return (
        <div className={cx("connect-wallet")}>
            {isMobileScreenLTE ? <MobileWalletButton /> : DesktopWalletButton}
            {renderModalDisconnectDiscord}
        </div>
    );
};

export default ConnectWalletButton;
