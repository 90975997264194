import React from "react";

const NotificationIcon: React.FC<{ className: string }> = ({ className }) => {
    return (
        <svg className={className} width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M19.2003 15.5945V15.4945C19.2003 15.3945 19.2003 15.2945 19.1003 15.1945L17.9003 12.3945V7.79454V7.49454V7.19454C17.6003 3.19454 14.1003 0.0945435 10.0003 0.0945435C5.70029 0.0945435 2.20029 3.29454 2.10029 7.49454V7.79454V12.3945L0.900293 15.1945L0.800293 15.2945V15.4945C0.800293 15.7945 0.800293 16.1945 1.00029 16.3945C1.20029 16.6945 1.40029 16.8945 1.70029 16.9945L1.90029 17.0945C2.00029 17.0945 2.20029 17.1945 2.30029 17.1945H6.60029C6.70029 18.0945 7.00029 18.8945 7.70029 19.4945C8.30029 20.0945 9.20029 20.3945 10.0003 20.3945C10.9003 20.3945 11.7003 20.0945 12.3003 19.4945C12.9003 18.8945 13.3003 18.0945 13.4003 17.1945H17.7003H17.9003H18.1003C18.4003 17.0945 18.7003 16.8945 18.9003 16.6945C19.1003 16.3945 19.2003 16.0945 19.2003 15.7945V15.5945ZM8.30029 17.1945H11.7003C11.7003 17.5945 11.5003 17.9945 11.1003 18.2945C10.8003 18.5945 10.4003 18.6945 9.90029 18.6945H9.70029C9.30029 18.6945 8.90029 18.4945 8.70029 18.1945C8.50029 17.9945 8.30029 17.5945 8.30029 17.1945ZM3.70029 12.7945L3.80029 12.5945V7.79454V7.49454V7.19454C4.10029 4.09454 6.80029 1.69454 10.0003 1.69454C13.4003 1.69454 16.1003 4.19454 16.2003 7.39454V7.69454V12.3945L16.3003 12.6945L17.4003 15.3945H2.60029L3.70029 12.7945ZM2.30029 16.9945C2.20029 16.9945 2.10029 16.9945 2.00029 16.9945C2.10029 16.9945 2.20029 16.9945 2.30029 16.9945Z'
                fill='currentColor'
            />
        </svg>
    );
};

export default NotificationIcon;
