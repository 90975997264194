// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import CountDown from "react-countdown";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowBack } from "react-icons/io";
import cn from "classnames/bind";
import _, { isNil } from "lodash";
import styles from "./VerifyWalletDialog.module.scss";
import ActiveWalletImageDark from "src/assets/images/ActiveWalletImageDark.png";
import ActiveWalletEmailImageDark from "src/assets/images/ActiveWalletEmailImageDark.png";
import ActivatedWalletImageDark from "src/assets/images/ActivatedWalletImageDark.png";
import ActiveWalletImageLight from "src/assets/images/ActiveWalletImageLight.png";
import ActiveWalletEmailImageLight from "src/assets/images/ActiveWalletEmailImageLight.png";
import ActivatedWalletImageLight from "src/assets/images/ActivatedWalletImageLight.png";
import ErrorElement from "src/components/common/Error/Error";
import CloseIcon from "src/assets/icons/CloseIcon";
import { useHistory } from "react-router";
import { callApi } from "src/api";
import { setWalletInformation, selectWallet } from "src/store/slices/walletSlice";
import { selectThemeId } from "src/store/slices/activeThemeSlice";
import { themeIds } from "src/consts/theme";
import BackwardIcon from "src/components/icons/BackwardIcon";

const cx = cn.bind(styles);
const STEPS = {
    NOTIFICATION: 0,
    INPUT_EMAIL: 1,
    INPUT_VERIFICATION_CODE: 2,
    ACTIVATED: 3,
};

const VerifyWalletDialog = (props: any) => {
    const { setOpenVerifyDialog, notification, titleProps, isDescription = true, onClose, onSuccess } = props;

    const history = useHistory();
    const [open, setOpen] = useState(true);
    const [step, setStep] = useState(!isNil(notification) ? STEPS.NOTIFICATION : STEPS.INPUT_EMAIL);
    const [codeArray, setCodeArray] = useState(["", "", "", "", "", ""]);
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [sendCodeState, setSendCodeState] = useState(false);
    const [disableVerifyButton, setDisableVerifyButton] = useState(false);
    const transactionCodeRef = useRef("");
    const arrayCodeRef = useRef(null);
    const dispatch = useDispatch();
    const walletInformation = (info: any) => dispatch(setWalletInformation(info));
    const wallet = useSelector<IWallet>(selectWallet);

    const activeThemeId = useSelector(selectThemeId);
    const isDarkTheme = activeThemeId === themeIds.DARK;

    const handleSendCode = async () => {
        try {
            if (email === "") setErrorMessage("Email must not be empty!");
            const data = { email: email };
            setDisableVerifyButton(true);
            const result = await callApi({
                method: "post",
                url: `/providers/verify-email`,
                contentType: "application/json",
                data,
            });
            setDisableVerifyButton(false);
            if (result.status === 201) {
                setErrorMessage(null);
                setStep(STEPS.INPUT_VERIFICATION_CODE);
            } else setErrorMessage("Email is already in use!");
            transactionCodeRef.current = result?.data?.transactionCode;
        } catch (error) {
            setDisableVerifyButton(false);
            console.log(error.response);
            switch (error?.response?.data?.message) {
                case "Email already used":
                    setErrorMessage("Email is already in use!");
                    break;
                case "Please wait for 1 minute":
                    setErrorMessage("A verifcation code has been sent, please try again in 1 minute");
                    break;
                default:
                    break;
            }
        }
    };

    const handleVerify = async () => {
        try {
            const data = { verificationCode: `${transactionCodeRef.current} ${codeArray?.join("")}` };
            setDisableVerifyButton(true);
            const result = await callApi({
                method: "post",
                url: `/providers/confirm-email`,
                contentType: "application/json",
                data,
            });
            setDisableVerifyButton(false);
            if (result.status === 201) {
                setErrorMessage(null);
                walletInformation({
                    ...wallet,
                    isEmailVerified: true,
                });
                setStep(STEPS.ACTIVATED);
            } else setErrorMessage("Wrong or expired code!");
        } catch (error) {
            setErrorMessage("Wrong or expired code!");
            setDisableVerifyButton(false);
            console.log(error.response);
            switch (error?.response?.data?.message) {
                case "Email already used":
                    setErrorMessage("Email is already in use!");
                    break;
                case "Please wait for 1 minute":
                    setErrorMessage("This email has just received a verification code, please try again in 1 minute");
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (step === STEPS.ACTIVATED && onSuccess) {
            onSuccess();
        }
    }, [step]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const renderModalBody = () => {
        return (
            <div className={cx("modal-body")}>
                {step === STEPS.NOTIFICATION && (
                    <>
                        <img src={isDarkTheme ? ActiveWalletImageDark : ActiveWalletImageLight} className={cx("step-image")} />
                        <div style={{ fontSize: 22, marginTop: 0 }} className={cx("step-title")}>
                            {titleProps ?? "Your wallet hasn't been activated yet"}
                        </div>
                        {isDescription && (
                            <div className={cx("step-description")}>
                                Please activate your wallet to enable <br /> all functions on aiRight!
                            </div>
                        )}
                        <div className={cx("button-wrapper")}>
                            <button
                                disabled={disableVerifyButton}
                                onClick={() => {
                                    handleClose();
                                }}
                                className={cx("button-go-to-marketplace")}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={disableVerifyButton}
                                onClick={() => {
                                    setStep(STEPS.INPUT_EMAIL);
                                }}
                                className={cx("button-mint-nft")}
                            >
                                Activate wallet
                            </button>
                        </div>
                    </>
                )}
                {step === STEPS.INPUT_EMAIL && (
                    <>
                        <img src={isDarkTheme ? ActiveWalletImageDark : ActiveWalletImageLight} className={cx("step-image")} />
                        <div className={cx("step-title")}>Active your wallet</div>
                        <div className={cx("step-description")}>Verification code will be sent to your email:</div>
                        <Tooltip
                            title={
                                "This email will be mapped with your wallet and can’t be changed once your wallet is verified. Please check your email address carefully."
                            }
                            arrow
                            placement='right-start'
                        >
                            <input
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder='Enter your email address'
                                type='text'
                                className={cx("input-email")}
                            />
                        </Tooltip>

                        {!isNil(errorMessage) && errorMessage !== "" && (
                            <ErrorElement
                                style={{ marginBottom: 0, marginTop: 0, marginRight: "auto" }}
                                classNameCustom={cx("error-text-custom")}
                            >
                                {errorMessage}
                            </ErrorElement>
                        )}
                        <button
                            disabled={disableVerifyButton}
                            onClick={() => {
                                handleSendCode();
                            }}
                            className={cx("button-submit")}
                        >
                            Send Verification code
                        </button>
                    </>
                )}
                {step === STEPS.INPUT_VERIFICATION_CODE && (
                    <>
                        <img
                            src={isDarkTheme ? ActiveWalletEmailImageDark : ActiveWalletEmailImageLight}
                            className={cx("step-image")}
                        />
                        <div className={cx("step-title")}>Check your email</div>
                        <div className={cx("step-description")}>
                            Please enter the verification code sent to <br />
                            {email}
                        </div>

                        <div ref={arrayCodeRef} className={cx("code-wrapper")}>
                            {codeArray.map((item, index) => {
                                return (
                                    <input
                                        value={item}
                                        onKeyDown={(e) => {
                                            const reg = new RegExp("^[0-9]+$");
                                            errorMessage !== null && setErrorMessage(null);
                                            if (e.key === "Backspace" || e.key === "Delete") {
                                                if (item === "") {
                                                    arrayCodeRef?.current?.getElementsByTagName(`input`)?.[index - 1]?.focus?.();
                                                } else {
                                                    let value = _.cloneDeep(codeArray);
                                                    value[index] = "";
                                                    setCodeArray(value);
                                                }
                                            } else if (reg.test(e.key) && item === "") {
                                                let value = _.cloneDeep(codeArray);
                                                value[index] = e.key;
                                                setCodeArray(value);
                                                arrayCodeRef?.current?.getElementsByTagName(`input`)?.[index + 1]?.focus?.();
                                            }
                                        }}
                                        type='tel'
                                        max={0}
                                        min={9}
                                        maxLength={1}
                                        className={cx("input-code")}
                                    />
                                );
                            })}
                        </div>
                        {!isNil(errorMessage) && errorMessage !== "" && (
                            <ErrorElement custom style={{ marginBottom: 0, marginTop: 12 }} classNameCustom={cx("error-text-custom")}>
                                {errorMessage}
                            </ErrorElement>
                        )}
                        {sendCodeState === true ? (
                            <div className={cx("send-state")}>
                                Haven't received the mail?{" "}
                                <span
                                    style={{ marginLeft: 4 }}
                                    onClick={() => {
                                        setSendCodeState(false);
                                        handleSendCode();
                                    }}
                                >
                                    Send it again
                                </span>
                            </div>
                        ) : (
                            <div className={cx("send-state")}>
                                Email sent! Resend in
                                <CountDown
                                    date={new Date().getTime() + 60000}
                                    renderer={({ days, hours, minutes, seconds, completed }) => {
                                        return (
                                            <div style={{ marginLeft: 4 }}>
                                                0{minutes}:{seconds == 0 ? "00" : seconds < 10 ? "0" + seconds : seconds}
                                            </div>
                                        );
                                    }}
                                    onComplete={() => {
                                        setSendCodeState(true);
                                    }}
                                />
                            </div>
                        )}

                        <button
                            onClick={() => {
                                handleVerify();
                            }}
                            className={cx("button-submit")}
                            disabled={disableVerifyButton || codeArray?.join("")?.length !== 6 ? true : false}
                        >
                            Verify
                        </button>
                    </>
                )}
                {step === STEPS.ACTIVATED && !onSuccess && (
                    <>
                        <img src={isDarkTheme ? ActivatedWalletImageDark : ActivatedWalletImageLight} className={cx("step-image")} />
                        <div className={cx("step-title")}>Oraichain Wallet Activated!</div>
                        <div className={cx("step-description")}>
                            You now can start creating, selling and collecting artworks on aiRight Marketplace
                        </div>
                        <div className={cx("button-wrapper")}>
                            <div
                                onClick={() => {
                                    history.push("/marketplace");
                                    handleClose();
                                }}
                                className={cx("button-go-to-marketplace")}
                            >
                                Go to Marketplace
                            </div>
                            <div
                                onClick={() => {
                                    history.push("/ai-models");
                                    handleClose();
                                }}
                                className={cx("button-mint-nft")}
                            >
                                Mint NFT
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    return (
        <Dialog
            onBackdropClick={() => {
                setOpenVerifyDialog?.(false);
                handleClose();
            }}
            open={open}
        >
            <div className={cx("modal")}>
                <div className={cx("modal-header")}>
                    {step === STEPS.INPUT_VERIFICATION_CODE && (
                        <div
                            style={{ marginRight: "auto" }}
                            onClick={() => {
                                setErrorMessage(null);
                                setCodeArray(["", "", "", "", "", ""]);
                                setStep(STEPS.INPUT_EMAIL);
                            }}
                        >
                            <IoMdArrowBack className={cx("back-icon")} />
                        </div>
                    )}
                    <div
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                            setOpenVerifyDialog?.(false);
                            handleClose();
                        }}
                    >
                        <CloseIcon className={cx("close-icon")} />
                    </div>
                </div>
                {renderModalBody()}
            </div>
        </Dialog>
    );
};

export default VerifyWalletDialog;
