import React, { useContext } from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";
import cn from "classnames/bind";
import IconKCNight from "src/assets/icons/Topup/IconKCNight.svg";
import IconKCLight from "src/assets/icons/Topup/IconKCLight.svg";
import IconCardNight from "src/assets/icons/Topup/IconCardNight.svg";
import IconCardLight from "src/assets/icons/Topup/IconCardLight.svg";
//context
import NftContext from "src/components/pages/NftDetail/NftContext/NftContext";
//theme light dark
import { useSelector } from "react-redux";
import { selectThemeId } from "src/store/slices/activeThemeSlice";
import { themeIds } from "src/consts/theme";
//style
import styles from "./index.module.scss";
const cx = cn.bind(styles);

const TopUpModal: React.FC<{
    setVisibleTopUp: Function;
}> = ({ setVisibleTopUp }) => {
    //use Theme
    const activeThemeId = useSelector(selectThemeId);
    const isDarkTheme = activeThemeId === themeIds.DARK;
    //nft context
    const { contextAction } = useContext(NftContext);
    //handle click card
    const handleClickCard = () => {
        contextAction.setTypeTopUp("top-up");
        contextAction.setVisibleTopUp(true);
        setVisibleTopUp(false);
    };

    return (
        <Modal
            visible={true}
            footer={false}
            onCancel={() => setVisibleTopUp(false)}
            title='Select a top up method'
            width={540}
            centered
            className={cx("topup-modal")}
        >
            <div className={cx("wrap-topup")}>
                <a
                    href='https://trade.kucoin.com/trade/ORAI-USDT'
                    target='_blank'
                    rel='noopener noreferrer'
                    className={cx("box-item")}
                >
                    <div className={cx("icon")}>
                        <img src={isDarkTheme ? IconKCNight : IconKCLight} alt='' />
                    </div>
                    <div className={cx("content")}>
                        <div className={cx("title")}>KuCoin</div>
                        <div className={cx("desc")}>Buy or trade ORAI with all tools</div>
                    </div>
                </a>
                {/* <div className={cx('box-item')} onClick={handleClickCard}>
          <div className={cx('icon')}><img src={isDarkTheme ? IconCardNight : IconCardLight} alt="" /></div>
          <div className={cx('content')}>
            <div className={cx('title')}>Credit or debit card</div>
            <div className={cx('desc')}>Visa, Mastercard, AMEX, and Discover</div>
            <div className={cx('err')} style={{color: isDarkTheme ? '#f2c94c' : '#EE8100'}}>Only available for the transactions with at least $0.01</div>
          </div>
        </div> */}
            </div>
        </Modal>
    );
};

export default TopUpModal;
